import React, { useEffect, useRef, useState } from "react";
import recognition from "src/modules/recognition";
import speakImg from "../../../../../../../assets/speak.svg";
import listeningImg from "../../../../../../../assets/speaking.gif";
import imagesImg from "../../../../../../../assets/images.svg";
import { Subject } from "rxjs";
import * as O from "fp-ts/lib/Option";
import { useTreatments } from "@splitsoftware/splitio-react";
import { UUID } from "io-ts-types";
import { pipe } from "fp-ts/lib/function";
import * as TE from "fp-ts/lib/TaskEither";
import { useAuth0 } from "@auth0/auth0-react";
import teTryCatch from "src/modules/teTryCatch";
import { useMaybeLogin } from "src/modules/maybeLogin";

type Props = {
  onUploadFile?: React.ChangeEventHandler<HTMLInputElement>;
  speechContextPhrases: string[];
  enableUploadFiles: Boolean;
  sessionId: O.Option<UUID>;
  stopRecognition: Subject<boolean>;
  inputRef: React.MutableRefObject<HTMLDivElement>;
  sendMessage: (event: any) => Promise<void>;
  dirty: boolean;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
  color: string;
  enableSpeechRecognition: boolean;
  projectId: UUID;
};

function CustomButtons({
  onUploadFile,
  enableUploadFiles,
  sessionId,
  speechContextPhrases,
  stopRecognition,
  inputRef,
  sendMessage,
  setDirty,
  color,
  enableSpeechRecognition,
  projectId,
}: Props) {
  const [speaking, onSpeak] = useState(false);
  const [processing, onProcess] = useState(false);
  const [processRecognition, setProcessRecognition] = useState(
    new Subject<boolean>(),
  );

  useEffect(() => {
    const processRecognition = new Subject<boolean>();
    setProcessRecognition(processRecognition);
    const sub = stopRecognition.subscribe((stop) => {
      if (stop) {
        onSpeak(false);
      } else {
        onSpeak(true);
      }
    });

    return () => {
      // Clean up the subscription
      stopRecognition.next(true);
      sub.unsubscribe();
    };
  }, []);

  const canvasRef = useRef(null);

  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  const getAccessTokenSilentlyWithoutLogin = useMaybeLogin();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const current = canvasRef.current;
    if (speaking && current !== null) {
      onProcess(false);
      pipe(
        sessionId,
        O.fold(
          () => {
            const a = () => {};

            return TE.of(a);
          },
          (s) =>
            pipe(
              TE.fromTask(getAccessTokenSilentlyWithoutLogin),
              TE.chain((token) =>
                recognition(
                  onInputValueChange,
                  (transcript) => {
                    sendMessage(transcript);
                    onInputValueChange("");
                  },
                  speechContextPhrases,
                  stopRecognition,
                  processRecognition,
                  s,
                  current,
                  color,
                  onProcess,
                  projectId,
                  token,
                ),
              ),
            ),
        ),
        TE.fold(
          (e) => {
            console.log(e);
            alert(e);
            // TODO: check 401
            if (!isAuthenticated) {
              loginWithRedirect();
            }
            return TE.of(undefined);
          },
          () => {
            return TE.of(undefined);
          },
        ),
      )();
    }
  }, [speaking, canvasRef, sessionId, isLoading]);

  const onInputValueChange = (input: string) => {
    const el = inputRef.current;
    if (el) {
      el.innerHTML = input;
      setDirty(el.innerHTML !== "");
    }
  };

  return (
    <div
      className="custom-buttons-container"
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }}
    >
      <ul className="custom-buttons">
        {enableSpeechRecognition ? (
          <li className="custom-list-button" title="Click or tap to speak">
            {speaking ? (
              <div className="rcw-wave-overlay">
                <canvas
                  title="Release when you finish talking and send the message."
                  ref={canvasRef}
                  className="rcw-wave-canvas"
                  width="1000"
                  height="350"
                />
                <span className="rcw-wave-text">
                  {processing ? (
                    "Processing"
                  ) : (
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-400 -400 800 800"
                        width="800"
                        height="800"
                        preserveAspectRatio="xMidYMid meet"
                        className="recording-dot"
                      >
                        <g clipPath="url(#__lottie_element_2)">
                          <g className="first">
                            <g>
                              <path
                                fill="rgb(13,116,255)"
                                d=" M0,-165.92750549316406 C91.57539367675781,-165.92750549316406 165.92750549316406,-91.57539367675781 165.92750549316406,0 C165.92750549316406,91.57539367675781 91.57539367675781,165.92750549316406 0,165.92750549316406 C-91.57539367675781,165.92750549316406 -165.92750549316406,91.57539367675781 -165.92750549316406,0 C-165.92750549316406,-91.57539367675781 -91.57539367675781,-165.92750549316406 0,-165.92750549316406z"
                              ></path>
                              <path
                                strokeLinecap="butt"
                                strokeLinejoin="miter"
                                fillOpacity="0"
                                strokeMiterlimit="4"
                                stroke="rgb(13,116,255)"
                                strokeWidth="0"
                                d=" M0,-165.92750549316406 C91.57539367675781,-165.92750549316406 165.92750549316406,-91.57539367675781 165.92750549316406,0 C165.92750549316406,91.57539367675781 91.57539367675781,165.92750549316406 0,165.92750549316406 C-91.57539367675781,165.92750549316406 -165.92750549316406,91.57539367675781 -165.92750549316406,0 C-165.92750549316406,-91.57539367675781 -91.57539367675781,-165.92750549316406 0,-165.92750549316406z"
                              ></path>
                            </g>
                          </g>
                          <g className="second">
                            <g>
                              <path
                                fill="rgb(13,116,255)"
                                d=" M0,-165.92750549316406 C91.57539367675781,-165.92750549316406 165.92750549316406,-91.57539367675781 165.92750549316406,0 C165.92750549316406,91.57539367675781 91.57539367675781,165.92750549316406 0,165.92750549316406 C-91.57539367675781,165.92750549316406 -165.92750549316406,91.57539367675781 -165.92750549316406,0 C-165.92750549316406,-91.57539367675781 -91.57539367675781,-165.92750549316406 0,-165.92750549316406z"
                              ></path>
                              <path
                                strokeLinecap="butt"
                                strokeLinejoin="miter"
                                fillOpacity="0"
                                strokeMiterlimit="4"
                                stroke="rgb(13,116,255)"
                                strokeWidth="0"
                                d=" M0,-165.92750549316406 C91.57539367675781,-165.92750549316406 165.92750549316406,-91.57539367675781 165.92750549316406,0 C165.92750549316406,91.57539367675781 91.57539367675781,165.92750549316406 0,165.92750549316406 C-91.57539367675781,165.92750549316406 -165.92750549316406,91.57539367675781 -165.92750549316406,0 C-165.92750549316406,-91.57539367675781 -91.57539367675781,-165.92750549316406 0,-165.92750549316406z"
                              ></path>
                            </g>
                          </g>
                          <g className="third">
                            <g>
                              <path
                                fill="rgb(13,116,255)"
                                d=" M0,-165.92750549316406 C91.57539367675781,-165.92750549316406 165.92750549316406,-91.57539367675781 165.92750549316406,0 C165.92750549316406,91.57539367675781 91.57539367675781,165.92750549316406 0,165.92750549316406 C-91.57539367675781,165.92750549316406 -165.92750549316406,91.57539367675781 -165.92750549316406,0 C-165.92750549316406,-91.57539367675781 -91.57539367675781,-165.92750549316406 0,-165.92750549316406z"
                              ></path>
                              <path
                                strokeLinecap="butt"
                                strokeLinejoin="miter"
                                fillOpacity="0"
                                strokeMiterlimit="4"
                                stroke="rgb(13,116,255)"
                                strokeWidth="0"
                                d=" M0,-165.92750549316406 C91.57539367675781,-165.92750549316406 165.92750549316406,-91.57539367675781 165.92750549316406,0 C165.92750549316406,91.57539367675781 91.57539367675781,165.92750549316406 0,165.92750549316406 C-91.57539367675781,165.92750549316406 -165.92750549316406,91.57539367675781 -165.92750549316406,0 C-165.92750549316406,-91.57539367675781 -91.57539367675781,-165.92750549316406 0,-165.92750549316406z"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </svg>{" "}
                      Listening
                    </span>
                  )}
                </span>
              </div>
            ) : null}

            <button
              type="button"
              className="rcw-custom-button"
              onClick={() => {
                if (!speaking) {
                  stopRecognition.next(false);
                } else {
                  processRecognition.next(true);
                }
              }}
              title="Click to speak. Click again to stop."
            >
              <img
                src={speakImg}
                alt={"Speak"}
                className="rcw-custom-image"
                draggable="false"
              />
              <span>
                {speaking ? (processing ? "Wait" : "Talk now") : "Speak"}
              </span>
            </button>
          </li>
        ) : null}

        {enableUploadFiles ? (
          <li
            className="custom-list-button"
            title="Upload images, invoices or files"
          >
            <label htmlFor="imageInput" className="rcw-custom-button">
              <img
                src={imagesImg}
                alt={"Files"}
                className="rcw-custom-image"
                draggable="false"
              />
              <span>Upload files</span>
            </label>
            <input
              multiple
              className="rcw-image-input"
              id="imageInput"
              type="file"
              onChange={onUploadFile}
            />
          </li>
        ) : null}
      </ul>
    </div>
  );
}

export default CustomButtons;
