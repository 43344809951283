import { combineReducers, compose } from "redux";

import behavior from "./reducers/behaviorReducer";
import messages from "./reducers/messagesReducer";
import quickButtons from "./reducers/quickButtonsReducer";
import preview from "./reducers/fullscreenPreviewReducer";
import { configureStore } from "@reduxjs/toolkit";

const reducer = combineReducers({ behavior, messages, quickButtons, preview });

export default configureStore({ reducer });
