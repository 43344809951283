import { useAuth0 } from "@auth0/auth0-react";
import { pipe } from "fp-ts/lib/function";
import teTryCatch from "./teTryCatch";
import * as TE from "fp-ts/lib/TaskEither";
import * as T from "fp-ts/lib/Task";

export const useMaybeLogin = () => {
  const { getAccessTokenSilently } = useAuth0();

  const getAccessTokenSilentlyMaybeLogin = pipe(
    teTryCatch(() => getAccessTokenSilently()),
    TE.fold(
      (e) => {
        console.error(e);
        return T.of("publicToken");
      },
      (token) => T.of(token),
    ),
  );

  return getAccessTokenSilentlyMaybeLogin;
};
