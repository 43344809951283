import forceEnv from "force-env";
import forceBool from "force-bool";

const settings = {
  // TODO: remove unused env vars
  runtimeDomain: forceEnv("REACT_APP_RUNTIME_DOMAIN"),
  runtimePath: forceEnv("REACT_APP_RUNTIME_PATH"),
  engineDomain: forceEnv("REACT_APP_ENGINE_DOMAIN"),
  enginePath: forceEnv("REACT_APP_ENGINE_PATH"),
  debugger: forceBool("REACT_APP_DEBUGGER"),
  clientHandoverDomain: forceEnv("REACT_APP_CLIENT_HANDOVER_DOMAIN"),
};

export default settings;
