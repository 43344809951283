import { Provider } from "react-redux";

import Widget from "./components/Widget";

import store from "./store";

import { AnyFunction } from "./utils/types";

import * as O from "fp-ts/lib/Option";
import { UUID } from "io-ts-types";
import { SpeechSynthesisVoiceType } from "src/modules/types";

type Props = {
  handleNewUserMessage: AnyFunction;
  handleQuickButtonClicked?: AnyFunction;
  title?: string;
  speechSynthesisVoiceID: string;
  speechSynthesisVoiceType: SpeechSynthesisVoiceType;
  speechSynthesisVoiceLocale: string | null;
  voiceToken: string;
  titleAvatar?: string;
  subtitle?: string;
  senderPlaceHolder?: string;
  showCloseButton?: boolean;
  fullScreenMode?: boolean;
  autofocus?: boolean;
  profileAvatar?: string;
  profileClientAvatar?: string;
  launcher?: AnyFunction;
  handleTextInputChange?: (event: any) => void;
  chatId?: string;
  handleToggle?: AnyFunction;
  launcherOpenLabel?: string;
  launcherCloseLabel?: string;
  launcherCloseImg?: string;
  launcherOpenImg?: string;
  sendButtonAlt?: string;
  showTimeStamp?: boolean;
  imagePreview?: boolean;
  zoomStep?: number;
  emojis?: boolean;
  handleSubmit?: AnyFunction;
  showBadge?: boolean;
  resizable?: boolean;

  speechContextPhrases: string[];
  speechContextBoosts: O.Option<number>[];
  enableUploadFiles: boolean;
  sessionId: O.Option<UUID>;
  onUploadFile?: React.ChangeEventHandler<HTMLInputElement>;
  isLoading: boolean;
  color: string;
  enableSpeechRecognition: boolean;
  projectId: UUID;
} & typeof defaultProps;

function ConnectedWidget({
  title,
  speechSynthesisVoiceID,
  speechSynthesisVoiceType,
  speechSynthesisVoiceLocale,
  voiceToken,
  titleAvatar,
  subtitle,
  senderPlaceHolder,
  showCloseButton,
  fullScreenMode,
  autofocus,
  profileAvatar,
  profileClientAvatar,
  launcher,
  handleNewUserMessage,
  handleQuickButtonClicked,
  handleTextInputChange,
  chatId,
  handleToggle,
  launcherOpenLabel,
  launcherCloseLabel,
  launcherCloseImg,
  launcherOpenImg,
  sendButtonAlt,
  showTimeStamp,
  imagePreview,
  zoomStep,
  handleSubmit,
  showBadge,
  resizable,
  emojis,

  speechContextPhrases,
  speechContextBoosts,
  enableUploadFiles,
  sessionId,
  onUploadFile,
  isLoading,
  color,
  enableSpeechRecognition,
  projectId,
}: Props) {
  return (
    <Provider store={store}>
      <Widget
        title={title}
        speechSynthesisVoiceID={speechSynthesisVoiceID}
        speechSynthesisVoiceType={speechSynthesisVoiceType}
        speechSynthesisVoiceLocale={speechSynthesisVoiceLocale}
        voiceToken={voiceToken}
        titleAvatar={titleAvatar}
        subtitle={subtitle}
        handleNewUserMessage={handleNewUserMessage}
        handleQuickButtonClicked={handleQuickButtonClicked}
        senderPlaceHolder={senderPlaceHolder}
        profileAvatar={profileAvatar}
        profileClientAvatar={profileClientAvatar}
        showCloseButton={showCloseButton}
        fullScreenMode={fullScreenMode}
        autofocus={autofocus}
        customLauncher={launcher}
        handleTextInputChange={handleTextInputChange}
        chatId={chatId}
        handleToggle={handleToggle}
        launcherOpenLabel={launcherOpenLabel}
        launcherCloseLabel={launcherCloseLabel}
        launcherCloseImg={launcherCloseImg}
        launcherOpenImg={launcherOpenImg}
        sendButtonAlt={sendButtonAlt}
        showTimeStamp={showTimeStamp}
        imagePreview={imagePreview}
        zoomStep={zoomStep}
        handleSubmit={handleSubmit}
        showBadge={showBadge}
        resizable={resizable}
        emojis={emojis}
        speechContextPhrases={speechContextPhrases}
        speechContextBoosts={speechContextBoosts}
        enableUploadFiles={enableUploadFiles}
        sessionId={sessionId}
        onUploadFile={onUploadFile}
        isLoading={isLoading}
        color={color}
        enableSpeechRecognition={enableSpeechRecognition}
        projectId={projectId}
      />
    </Provider>
  );
}

const defaultProps = {
  title: "Welcome",
  subtitle: "This is your chat subtitle",
  senderPlaceHolder: "Type a message...",
  showCloseButton: true,
  fullScreenMode: false,
  autofocus: true,
  chatId: "rcw-chat-container",
  launcherOpenLabel: "Open chat",
  launcherCloseLabel: "Close chat",
  launcherOpenImg: "",
  launcherCloseImg: "",
  sendButtonAlt: "Send",
  showTimeStamp: true,
  imagePreview: false,
  zoomStep: 80,
  showBadge: true,
};
ConnectedWidget.defaultProps = defaultProps;

export default ConnectedWidget;
