import React, { ComponentType, useState } from "react";
import logo from "./ailogick.png";
import { addUserMessage, Widget } from "./react-chat-widget";
import { handleOrPushUserMessage, NewDebug } from "./modules/handlers";
import {
  InputType,
  Input,
  TextInput,
  ReturnProject,
  ProcessedFormResponse,
  OnNewMessage,
  UploadFileReceiptT,
  SpeechSynthesisVoiceType,
  QuickReply,
} from "./modules/types";
import styled from "styled-components";
import color from "color";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import { OnFields, ShowForm } from "./modules/response";
import { autoResize, Size } from "./modules/iframe";
import { UUID } from "io-ts-types";
import { post } from "./modules/axios";
import * as TE from "fp-ts/lib/TaskEither";
import teTryCatch from "./modules/teTryCatch";
import * as T from "fp-ts/lib/Task";
import { decode } from "fp-ts-extras/lib/TaskEither";
import * as A from "fp-ts/lib/Array";
import { useAuth0 } from "@auth0/auth0-react";
import { useMaybeLogin } from "./modules/maybeLogin";

const isFileImage = (file: File) => {
  const imageTypes = ["image/jpeg", "image/png", "image/gif"];
  return imageTypes.includes(file.type);
};

const getFileMD = (isImage: boolean, filename: string, filepath: string) => {
  return isImage ? `![${filename}](${filepath})` : `[${filename}](${filepath})`;
};

const Wrapper = styled.div<{
  floating: boolean;
  isMobile: boolean;
  isDark: boolean;
}>`
  position: ${(props) => (props.floating ? "fixed" : "static")};
  right: 20px;
  bottom: 20px;
  z-index: 100;
  display: flex;

  img {
    display: inline;
  }

  a {
    color: -webkit-link;
    text-decoration: underline;
  }

  ol {
    list-style-type: decimal;
    padding-left: 15px;
  }

  ul {
    list-style-type: disc;
    padding-left: 15px;
  }

  blockquote {
    border-left: 5px solid #eee;
  }

  .rcw-launcher {
    background-color: ${(props) => props.color};
    display: inline-block;
    margin: 10px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rcw-header {
    background-color: ${(props) => props.color};
    color: ${(props) => (props.isDark ? "#fff" : "#000")};
    border-radius: ${(props) => (props.isMobile ? "0 0 0 0" : "10px 10px 0 0")};
    align-items: center;
  }

  .rcw-client > .rcw-message-text {
    color: ${(props) => (props.isDark ? "#fff" : "#000")};
    background-color: ${(props) => props.color};
  }

  .rcw-message-text {
    max-width: 300px;
    user-select: text;
  }

  .rcw-conversation-container .rcw-close-button {
    background-color: ${(props) => props.color};
  }

  .rcw-widget-container,
  .rcw-launcher {
    position: static;
  }

  .rcw-conversation-container {
    height: ${(props) => (props.isMobile ? "100%" : "585px")};
    width: ${(props) => (props.isMobile ? "100%" : "370px")};
    max-width: unset;
    position: ${(props) => (props.isMobile ? "fixed" : "static")};
    top: ${(props) => (props.isMobile ? "0" : "auto")};
    left: ${(props) => (props.isMobile ? "0" : "auto")};
    z-index: 1;
    background-color: white;
  }

  .rcw-widget-container {
    justify-content: flex-end;
    z-index: 1;
    height: auto;
    width: auto;
  }

  .rcw-sender {
    border-radius: ${(props) => (props.isMobile ? "0 0 0 0" : "0 0 10px 10px")};
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-height: unset;
  }

  .rcw-avatar {
    border-radius: 0;
  }

  .quick-button {
    border: 2px solid ${(props) => props.color};
  }

  .quick-button:active {
    background: ${(props) => props.color};
    color: ${(props) => (props.isDark ? "#fff" : "#000")};
  }

  .quick-buttons-container {
    white-space: break-spaces;
    overflow-x: unset;

    .quick-list-button {
      margin-top: 5px;
    }
  }

  .rcw-input {
    max-height: unset;
  }

  .rcw-input[data-placeholder]:empty:before {
    cursor: text;
  }

  .rcw-new-message {
    width: calc(100% - 50px);
  }

  .rcw-send-icon-svg {
    fill: ${(props) => props.color};
    stroke: ${(props) => props.color};
  }

  .rcw-custom-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    color: #808080;
    font-weight: bold;
  }

  .rcw-custom-image {
    width: 38px;
    height: 38px;
  }

  .rcw-image-input {
    display: none;
  }

  .rcw-wave-overlay {
    position: absolute;
    left: 0;
    top: -60px;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
  }

  .rcw-wave-canvas {
    width: 97%;
    height: 70%;
  }

  .rcw-wave-text {
    color: #808080;
    font-weight: bold;
  }

  .custom-buttons-container {
    background: $white;
    position: relative;
    -webkit-user-select: none;
    -webkit-touch-callout: none;

    .custom-buttons {
      list-style: none;
      padding: 0;
      margin: 0;
      text-align: center;

      .custom-list-button {
        display: inline-block;
        width: 90px;

        .rcw-custom-button {
          padding: 10px;
          width: 100%;
          text-wrap: nowrap;
        }
      }
    }
  }

  @keyframes ripple {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    80% {
      transform: scale(2);
      opacity: 0;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }

  .recording-dot {
    width: 18px;
    height: 18px;
    display: inline;

    .first {
      animation: ripple 2s ease-in infinite;
    }

    .second {
      animation: ripple 2s ease-in infinite;
      animation-delay: 0.5s;
    }
  }
`;

const ChatbotWidget: ComponentType<{
  onHistory: (a: Input) => void;
  newDebug: NewDebug;
  settings: ReturnProject;
  fallbackMessage: string;
  fields: O.Option<ProcessedFormResponse>;
  onFields: OnFields;
  showForm: ShowForm;
  shown: boolean;
  floating: boolean;
  isMobile: boolean;
  speechContextPhrases: string[];
  speechContextBoosts: O.Option<number>[];
  projectId: UUID;
  sessionId: O.Option<UUID>;
  onNewMessage: OnNewMessage;
  parentLocation: Location;
  enableSpeechRecognition: boolean;
  speechSynthesisVoiceType: SpeechSynthesisVoiceType;
  speechSynthesisVoiceID: string;
  speechSynthesisVoiceLocale: string | null;
  enableUploadFiles: boolean;
  titleAvatar: string;
  voiceToken: string;
}> = ({
  onHistory,
  newDebug,
  settings,
  fields,
  onFields,
  showForm,
  floating,
  isMobile,
  speechContextPhrases,
  speechContextBoosts,
  projectId,
  sessionId,
  onNewMessage,
  parentLocation,
  enableSpeechRecognition,
  speechSynthesisVoiceType,
  speechSynthesisVoiceID,
  speechSynthesisVoiceLocale,
  enableUploadFiles,
  titleAvatar,
  voiceToken,
}) => {
  const [showFormAndButton, changeShowFormAndButton] = useState(true);

  const [isLoading, onLoading] = useState(false);

  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const getAccessTokenSilentlyWithoutLogin = useMaybeLogin();

  const handleNewUserInputMessage = async (
    newMessage: string,
    quickReply: QuickReply | null,
  ) => {
    try {
      const token = await getAccessTokenSilentlyWithoutLogin();
      const input: TextInput = {
        value: newMessage,
        type: InputType.Text,
      };
      onHistory(input);
      onLoading(true);
      await handleOrPushUserMessage(
        newDebug,
        input,
        quickReply,
        settings.fallbackMessage,
        onFields,
        showForm,
        floating,
        speechSynthesisVoiceType,
        onNewMessage,
        sessionId,
        parentLocation,
        speechSynthesisVoiceID,
        speechSynthesisVoiceLocale,
        voiceToken,
        projectId,
        token,
      )();
    } catch (err) {
      // TODO: check 401
      if (!isAuthenticated) {
        loginWithRedirect();
      }
    }
    onLoading(false);
  };

  const isDark = color(settings.widgetColor).isDark();

  return (
    <Wrapper
      color={settings.widgetColor}
      floating={floating}
      isMobile={isMobile}
      isDark={isDark}
    >
      {pipe(
        fields,
        O.fold(
          () => null,
          () =>
            showFormAndButton
              ? // <FormResponse
                //   fields={fs}
                //   newDebug={newDebug}
                //   fallbackMessage={fallbackMessage}
                //   onFields={onFields}
                //   showForm={showForm}
                //   shown={shown}
                //   isMobile={isMobile}
                //   floating={floating}
                //   sessionId={sessionId}
                //   onNewMessage={onNewMessage}
                //   parentLocation={parentLocation}
                //   speechSynthesisVoiceType={speechSynthesisVoiceType}
                //   voiceID={speechSynthesisVoiceID}
                //   voiceToken={voiceToken}
                // ></FormResponse>
                null
              : null,
        ),
      )}
      <Widget
        showCloseButton={false}
        handleToggle={(open: boolean) => {
          if (!floating) {
            autoResize(open && isMobile ? Size.FullScreen : Size.NotFullScreen);
          }

          changeShowFormAndButton(open);
        }}
        handleNewUserMessage={handleNewUserInputMessage}
        handleQuickButtonClicked={(quickReply: QuickReply) => {
          addUserMessage(quickReply.display);

          handleNewUserInputMessage("", quickReply);
        }}
        profileAvatar={logo}
        speechSynthesisVoiceID={speechSynthesisVoiceID}
        speechSynthesisVoiceType={speechSynthesisVoiceType}
        speechSynthesisVoiceLocale={speechSynthesisVoiceLocale}
        voiceToken={voiceToken}
        titleAvatar={titleAvatar}
        title={settings.widgetTitle}
        subtitle={settings.widgetDescription}
        autofocus={!isMobile}
        speechContextPhrases={speechContextPhrases}
        speechContextBoosts={speechContextBoosts}
        enableUploadFiles={enableUploadFiles}
        sessionId={sessionId}
        onUploadFile={async (e) => {
          if (!e.target.files) {
            return;
          }

          const tes = pipe(
            Array.from(e.target.files),
            A.map((file) => {
              const isImage = isFileImage(file);
              return pipe(
                sessionId,
                TE.fromOption(() => new Error("No session id")),
                TE.chain((sessionId) =>
                  pipe(
                    TE.fromTask(getAccessTokenSilentlyWithoutLogin),
                    TE.chain((token) =>
                      post(
                        "uploads",
                        {
                          sessionId: sessionId,
                          isPublic: true,
                        },
                        token,
                      ),
                    ),
                  ),
                ),
                TE.bind("receipt", (response) =>
                  decode(UploadFileReceiptT, response.data),
                ),
                TE.bind("compressedImage", ({ receipt }) => {
                  // const config = {
                  //   quality: 1,
                  //   maxWidth: 1920,
                  //   maxHeight: 1080,
                  //   autoRotate: true,
                  // };

                  const text = getFileMD(isImage, file.name, receipt.filename);

                  handleNewUserInputMessage(text, null);

                  return isImage ? TE.of(file) : TE.of(file);
                }),
                TE.bind("image", ({ receipt, compressedImage }) => {
                  const myHeaders = new Headers();
                  myHeaders.append("Content-Type", file.type);

                  const requestOptions = {
                    method: "PUT",
                    headers: myHeaders,
                    body: compressedImage as Blob,
                  };

                  return teTryCatch(() =>
                    fetch(receipt.signedURL, requestOptions),
                  );
                }),
                TE.map(({ receipt }) => {
                  const text = getFileMD(isImage, file.name, receipt.filename);

                  addUserMessage(text);
                  return null;
                }),
                TE.fold(
                  () => T.of(""),
                  () => T.of(""),
                ),
              );
            }),
            A.sequence(T.ApplicativePar),
          );

          await tes();

          e.target.value = "";
        }}
        isLoading={isLoading}
        color={isDark ? "#fff" : "#000"}
        enableSpeechRecognition={enableSpeechRecognition}
        projectId={projectId}
      />
    </Wrapper>
  );
};

export default ChatbotWidget;
