import { useEffect, ReactNode, useRef } from "react";
import ReactDOM from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import usePreview from "./usePreview";
import usePortal from "./usePortal";
import "./styles.scss";
import { GlobalState } from "../../../../store/types";
import { closeFullscreenPreview } from "../../../../store/actions";

import close from "../../../../../assets/close.svg";
import plus from "../../../../../assets/plus.svg";
import minus from "../../../../../assets/minus.svg";
import zoomIn from "../../../../../assets/zoom-in.svg";
import zoomOut from "../../../../../assets/zoom-out.svg";
import { FullscreenPreviewActions } from "src/react-chat-widget/src/store/actions/types";
import { Dispatch } from "redux";

type Props = {
  fullScreenMode?: boolean;
  zoomStep?: number;
};

export default function FullScreenPreview({ fullScreenMode, zoomStep }: Props) {
  const { state, initFileSize, onZoomIn, onZoomOut, onResizePageZoom } =
    usePreview(zoomStep);

  const dispatch = useDispatch<Dispatch<FullscreenPreviewActions>>();
  const { src, alt, width, height, visible } = useSelector(
    (state: GlobalState) => ({
      src: state.preview.src,
      alt: state.preview.alt,
      width: state.preview.width,
      height: state.preview.height,
      visible: state.preview.visible,
    }),
  );

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (src) {
      initFileSize(width, height);
    }
  }, [src]);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.focus();
    }
  }, [divRef.current]);

  const pDom = usePortal();

  const onClosePreview = () => {
    dispatch(closeFullscreenPreview());
  };

  const childNode: ReactNode = (
    <div
      className="rcw-previewer-container"
      tabIndex={-1}
      ref={divRef}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          onClosePreview();
        }
      }}
    >
      <div className="rcw-previewer-veil">
        <div className="rcw-previewer-overlay" onClick={onClosePreview}></div>
        <img
          {...state.layout}
          src={src}
          className="rcw-previewer-image"
          alt={alt}
        />
      </div>
      <button
        className="rcw-previewer-button rcw-previewer-close-button"
        onClick={onClosePreview}
      >
        <img src={close} className="rcw-previewer-icon" />
      </button>
      <div className="rcw-previewer-tools">
        <button className="rcw-previewer-button" onClick={onResizePageZoom}>
          <img
            src={state.zoom ? zoomOut : zoomIn}
            className="rcw-previewer-icon"
            alt="reset zoom"
          />
        </button>

        <button className="rcw-previewer-button" onClick={onZoomIn}>
          <img src={plus} className="rcw-previewer-icon" alt="zoom in" />
        </button>
        <button className="rcw-previewer-button" onClick={onZoomOut}>
          <img src={minus} className="rcw-previewer-icon" alt="zoom out" />
        </button>
      </div>
    </div>
  );

  return visible ? ReactDOM.createPortal(childNode, pDom) : null;
}
