import { pipe } from "fp-ts/lib/function";
import { teGetItem, teSetItem } from "./localStorage";
import * as TE from "fp-ts/lib/TaskEither";
import * as T from "fp-ts/lib/Task";
import { UUID } from "io-ts-types";
import { decode } from "fp-ts-extras/lib/TaskEither";
import * as t from "io-ts";
import * as A from "fp-ts/lib/Array";

const sessionIdsKey = "sessionIds";

export const setSessionId = (sessionId: UUID): TE.TaskEither<Error, void> => {
  return pipe(
    getSessionIds,
    TE.fold(
      (_) => T.of([]),
      (sessionIds) => T.of(sessionIds)
    ),
    TE.fromTask,
    TE.chain((ids) => teSetItem(sessionIdsKey, pipe(ids, A.append(sessionId))))
  );
};

export const getSessionIds: TE.TaskEither<Error, UUID[]> = pipe(
  sessionIdsKey,
  teGetItem,
  TE.chain((sessionIds) => decode(t.array(UUID), sessionIds))
);
