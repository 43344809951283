import React, { ComponentType, useEffect } from "react";
import Chatbot from "./Chatbot";
import { pipe } from "fp-ts/lib/function";
import { getProject } from "./modules/getPathname";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import { ready } from "./modules/iframe";
import { decode } from "fp-ts-extras/lib/TaskEither";
import { UUID } from "io-ts-types";
import * as TE from "fp-ts/lib/TaskEither";
import * as T from "fp-ts/lib/Task";

const App: ComponentType = () => {
  const [isMobile, changeSize] = React.useState(true);
  const [parentLocation, updateParentLocation] = React.useState<Location>();
  const [projectId, setProjectId] = React.useState<
    O.Option<E.Either<string, UUID>>
  >(O.none);

  useEffect(() => {
    pipe(
      getProject,
      TE.chain((projectId) => decode(UUID, projectId)),
      TE.fold(
        (err) => T.of(E.left(err.toString())),
        (projectId) => T.of(E.right(projectId)),
      ),
      T.map((p) => setProjectId(O.some(p))),
    )();
  }, []);

  useEffect(() => {
    window.addEventListener("message", (e: MessageEvent) => {
      if (e.data.hasOwnProperty("isMobile")) {
        if (e.data.isMobile !== isMobile) {
          changeSize(e.data.isMobile);
        }
      }

      if (e.data.hasOwnProperty("location")) {
        updateParentLocation(e.data.location);

        clearTimeout(ownLocation);
      }
    });

    ready();

    // for local dev
    const ownLocation = setTimeout(() => {
      updateParentLocation((l) => {
        if (l == null) {
          return window.location;
        }

        return l;
      });
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (parentLocation == null) {
    return null;
  }

  return pipe(
    projectId,
    O.fold(
      () => <div>Loading...</div>,
      E.fold(
        (err) => <div>{err}</div>,
        (projectId) => (
          <Chatbot
            projectId={projectId}
            projectSettings={O.none}
            floating={false}
            isMobile={isMobile}
            parentLocation={parentLocation}
          />
        ),
      ),
    ),
  );
};

export default App;
