import { startChat, sendMessage } from "./chatRequests";
import { addUserMessage } from "../react-chat-widget";
import { pipe, constant } from "fp-ts/lib/function";
import * as TE from "fp-ts/lib/TaskEither";
import * as T from "fp-ts/lib/Task";
import * as t from "io-ts";
import * as O from "fp-ts/lib/Option";
import {
  OutputPayload,
  ResponseType,
  Input,
  InputType,
  ResponseWithText,
  ProcessedQuickReplyResponse,
  ResponsesWithText,
  StartChatResponseDataT,
  OutputPayloadT,
  OnNewMessage,
  SpeechSynthesisVoiceType,
  StartChatResponseData,
  QuickReply,
} from "./types";
import { decode } from "fp-ts-extras/lib/TaskEither";
import {
  addErrorResponse,
  addFormResponse,
  addQuickReplyResponse,
  addRteResponse,
  OnFields,
  ShowForm,
} from "./response";
import * as IO from "fp-ts/lib/IO";
import * as E from "fp-ts/lib/Either";
import * as A from "fp-ts/lib/Array";
import { identity } from "io-ts";
import { autoResize, Size } from "./iframe";
import { UUID } from "io-ts-types";
import { setSessionId } from "./session";
import * as J from "fp-ts/lib/Json";
import socket from "./socket";
import { get } from "./axios";

export const voidTask = T.of(undefined);

export const voidIO = IO.of(undefined);

export type NewDebug = (
  value: React.SetStateAction<E.Either<unknown, OutputPayload>[]>,
) => void;

export const init = (
  projectId: UUID,
  newDebug: NewDebug,
  floating: boolean,
  onFields: OnFields,
  showForm: ShowForm,
  parentLocation: Location,
  onNewMessage: OnNewMessage,
  token: string,
): TE.TaskEither<
  Error,
  { websocket: WebSocket; response: StartChatResponseData; voiceToken: string }
> => {
  return pipe(
    startChat(projectId, parentLocation, token),
    TE.chain(({ data }) => decode(StartChatResponseDataT, data)),
    TE.chain((data) =>
      pipe(setSessionId(data.sessionId), TE.map(constant(data))),
    ),
    TE.map((data) => ({ response: data })),
    TE.bind("voiceToken", ({ response }) => {
      if (
        response.project.speechSynthesisVoiceType ===
        SpeechSynthesisVoiceType.VoiceOnly
      ) {
        return pipe(
          "get-speech-token",
          (p) => get(p, token),
          TE.chain(({ data }) => decode(t.string, data)),
        );
      } else {
        return TE.of("");
      }
    }),
    TE.bind(
      "websocket",
      ({ response, voiceToken }): TE.TaskEither<Error, WebSocket> => {
        // const project = response.project;

        pendingMessages.forEach((message) => {
          handleNewUserMessage(
            newDebug,
            message,
            null,
            response.project.fallbackMessage,
            onFields,
            showForm,
            floating,
            response.project.speechSynthesisVoiceType,
            onNewMessage,
            response.sessionId,
            parentLocation,
            response.project.speechSynthesisVoiceID,
            response.project.speechSynthesisVoiceLocale,
            voiceToken,
            projectId,
            token,
          )();
        });
        handleResponses(
          response.responses,
          onFields,
          showForm,
          response.project.fallbackMessage,
          response.project.speechSynthesisVoiceType,
          response.project.speechSynthesisVoiceID,
          response.project.speechSynthesisVoiceLocale,
          voiceToken,
          projectId,
        );

        if (floating) {
          return TE.of(
            socket(
              response.sessionId,
              onFields,
              showForm,
              response.project.fallbackMessage,
              response.project.speechSynthesisVoiceType,
              response.project.speechSynthesisVoiceID,
              response.project.speechSynthesisVoiceLocale,
              voiceToken,
              projectId,
            ),
          );
        }

        // const origin = parentLocation.origin;

        // const domainAllowed = project.domains.includes(origin);

        // if (domainAllowed) {
        return TE.of(
          socket(
            response.sessionId,
            onFields,
            showForm,
            response.project.fallbackMessage,
            response.project.speechSynthesisVoiceType,
            response.project.speechSynthesisVoiceID,
            response.project.speechSynthesisVoiceLocale,
            voiceToken,
            projectId,
          ),
        );
        // }

        // const errorMessage = `The AI Logick widget is not allowed to load on this domain: ${origin}`;

        // console.error(errorMessage);
        // return TE.left(new Error(errorMessage));
      },
    ),
  );
};

const getQuickReplies = (
  responses: ResponseWithText[],
): ProcessedQuickReplyResponse[] => {
  const empty: ProcessedQuickReplyResponse[] = [];
  return pipe(
    responses,
    A.reduce(empty, (responses, response) =>
      response.type === ResponseType.QuickReply
        ? pipe(responses, A.append(response))
        : responses,
    ),
  );
};

export const handleResponses = (
  responses: ResponsesWithText,
  onFields: OnFields,
  showForm: ShowForm,
  fallbackMessage: string,
  speechSynthesisVoiceType: SpeechSynthesisVoiceType,
  voiceID: string,
  locale: string | null,
  voiceToken: string,
  projectId: UUID,
) => {
  pipe(
    responses,
    A.map((response) => {
      switch (response.type) {
        case ResponseType.Rte:
          return addRteResponse(
            response,
            speechSynthesisVoiceType,
            voiceID,
            locale,
            voiceToken,
            projectId,
          );
        case ResponseType.Form:
          return addFormResponse(response, onFields, showForm);
        case ResponseType.Handover:
        case ResponseType.QuickReply:
        case ResponseType.Webhook:
          return undefined;
        default:
          return addErrorResponse(fallbackMessage);
      }
    }),
  );

  pipe(
    responses,
    getQuickReplies,
    A.map((response) => pipe(response.quickReply)),
    addQuickReplyResponse,
  );
};

const pendingMessages: Input[] = [];

export const handleOrPushUserMessage = (
  newDebug: NewDebug,
  input: Input,
  quickReply: QuickReply | null,
  fallbackMessage: string,
  onFields: OnFields,
  showForm: ShowForm,
  floating: boolean,
  speechSynthesisVoiceType: SpeechSynthesisVoiceType,
  onNewMessage: OnNewMessage,
  sessionId: O.Option<UUID>,
  parentLocation: Location,
  voiceID: string,
  locale: string | null,
  voiceToken: string,
  projectId: UUID,
  token: string,
): T.Task<void> => {
  return pipe(
    sessionId,
    O.fold(
      () => {
        pendingMessages.push(input);
        return voidTask;
      },
      (s) =>
        handleNewUserMessage(
          newDebug,
          input,
          quickReply,
          fallbackMessage,
          onFields,
          showForm,
          floating,
          speechSynthesisVoiceType,
          onNewMessage,
          s,
          parentLocation,
          voiceID,
          locale,
          voiceToken,
          projectId,
          token,
        ),
    ),
  );
};

export const handleNewUserMessage = (
  newDebug: NewDebug,
  input: Input,
  quickReply: QuickReply | null,
  fallbackMessage: string,
  onFields: OnFields,
  showForm: ShowForm,
  floating: boolean,
  speechSynthesisVoiceType: SpeechSynthesisVoiceType,
  onNewMessage: OnNewMessage,
  sessionId: UUID,
  parentLocation: Location,
  voiceID: string,
  locale: string | null,
  voiceToken: string,
  projectId: UUID,
  token: string,
): T.Task<void> => {
  window.gtag("event", "message", {
    event_category: "message",
    sessionId: sessionId,
    input_value: input.value,
    input_type: input.type,
    parent_location: parentLocation.href,
    voice_id: voiceID,
    project_id: projectId,
  });

  addQuickReplyResponse([]);

  // renew websocket connection
  socket(
    sessionId,
    onFields,
    showForm,
    fallbackMessage,
    speechSynthesisVoiceType,
    voiceID,
    locale,
    voiceToken,
    projectId,
  );

  return pipe(
    TE.of(sessionId),
    TE.chainFirst((sessionId) => onNewMessage(sessionId, input)),
    TE.chain((sessionId) =>
      sendMessage(sessionId, input, quickReply, parentLocation, token),
    ),
    TE.chain(({ data }) => decode(OutputPayloadT, data)),
    TE.fold(
      (e) => {
        console.error(e);
        // addErrorResponse(fallbackMessage);

        // TODO: refactor the types
        const debug = pipe(
          e.message,
          J.parse,
          E.fold(() => e as unknown, identity),
        );

        newDebug((newDebugs) => pipe(newDebugs, A.append(E.left(debug))));
        return voidTask;
      },
      (data) => {
        pipe(
          O.fromNullable(data.responses),
          O.fold(
            () => {},
            (responses) => {
              return handleResponses(
                responses,
                onFields,
                showForm,
                fallbackMessage,
                speechSynthesisVoiceType,
                voiceID,
                locale,
                voiceToken,
                projectId,
              );
            },
          ),
        );

        newDebug((newDebugs) => pipe(newDebugs, A.append(E.right(data))));

        if (!floating) {
          autoResize(Size.DoNotChange);
        }

        return voidTask;
      },
    ),
  );
};

const replayUserNewMessage = (
  newDebug: NewDebug,
  message: Input,
  quickReply: QuickReply | null,
  fallbackMessage: string,
  onFields: OnFields,
  showForm: ShowForm,
  floating: boolean,
  speechSynthesisVoiceType: SpeechSynthesisVoiceType,
  onNewMessage: OnNewMessage,
  sessionId: UUID,
  parentLocation: Location,
  voiceID: string,
  locale: string,
  voiceToken: string,
  projectId: UUID,
  token: string,
): T.Task<void> => {
  switch (message.type) {
    case InputType.Text: {
      const render: IO.IO<void> = () => addUserMessage(message.value);

      return pipe(
        T.fromIO(render),
        T.chain(() =>
          handleNewUserMessage(
            newDebug,
            message,
            quickReply,
            fallbackMessage,
            onFields,
            showForm,
            floating,
            speechSynthesisVoiceType,
            onNewMessage,
            sessionId,
            parentLocation,
            voiceID,
            locale,
            voiceToken,
            projectId,
            token,
          ),
        ),
      );
    }
    default:
      console.error("Not implemented");
      return voidTask;
  }
};

export const handleReplay = (
  newDebug: NewDebug,
  fallbackMessage: string,
  messages: Input[],
  onFields: OnFields,
  showForm: ShowForm,
  floating: boolean,
  speechSynthesisVoiceType: SpeechSynthesisVoiceType,
  onNewMessage: OnNewMessage,
  sessionId: UUID,
  parentLocation: Location,
  voiceID: string,
  locale: string,
  voiceToken: string,
  projectId: UUID,
  token: string,
): T.Task<void> => {
  return pipe(
    messages,
    A.reduceRight(voidTask, (message, nextTask) =>
      pipe(
        replayUserNewMessage(
          newDebug,
          message,
          null,
          fallbackMessage,
          onFields,
          showForm,
          floating,
          speechSynthesisVoiceType,
          onNewMessage,
          sessionId,
          parentLocation,
          voiceID,
          locale,
          voiceToken,
          projectId,
          token,
        ),
        pipe(nextTask, constant, T.chain),
      ),
    ),
  );
};
