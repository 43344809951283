import { useDispatch } from "react-redux";

import { toggleChat, addUserMessage } from "../../store/actions";
import { isWidgetOpened } from "../../store/dispatcher";
import { AnyFunction } from "../../utils/types";

import WidgetLayout from "./layout";

import * as O from "fp-ts/lib/Option";
import { UUID } from "io-ts-types";
import { SpeechSynthesisVoiceType } from "src/modules/types";
import { Dispatch } from "redux";
import { AddUserMessage, ToggleChat } from "../../store/actions/types";

type Props = {
  title: string;
  speechSynthesisVoiceID: string;
  speechSynthesisVoiceType: SpeechSynthesisVoiceType;
  speechSynthesisVoiceLocale: string | null;
  voiceToken: string;
  titleAvatar?: string;
  subtitle: string;
  senderPlaceHolder: string;
  profileAvatar?: string;
  profileClientAvatar?: string;
  showCloseButton: boolean;
  fullScreenMode: boolean;
  autofocus: boolean;
  customLauncher?: AnyFunction;
  handleNewUserMessage: AnyFunction;
  handleQuickButtonClicked?: AnyFunction;
  handleTextInputChange?: (event: any) => void;
  chatId: string;
  handleToggle?: AnyFunction;
  launcherOpenLabel: string;
  launcherCloseLabel: string;
  launcherOpenImg: string;
  launcherCloseImg: string;
  sendButtonAlt: string;
  showTimeStamp: boolean;
  imagePreview?: boolean;
  zoomStep?: number;
  handleSubmit?: AnyFunction;
  showBadge?: boolean;
  resizable?: boolean;
  emojis?: boolean;

  speechContextPhrases: string[];
  speechContextBoosts: O.Option<number>[];
  enableUploadFiles: boolean;
  sessionId: O.Option<UUID>;
  onUploadFile?: React.ChangeEventHandler<HTMLInputElement>;
  isLoading: boolean;
  color: string;
  enableSpeechRecognition: boolean;
  projectId: UUID;
};

function Widget({
  title,
  speechSynthesisVoiceID,
  speechSynthesisVoiceType,
  speechSynthesisVoiceLocale,
  voiceToken,
  titleAvatar,
  subtitle,
  senderPlaceHolder,
  profileAvatar,
  profileClientAvatar,
  showCloseButton,
  fullScreenMode,
  autofocus,
  customLauncher,
  handleNewUserMessage,
  handleQuickButtonClicked,
  handleTextInputChange,
  chatId,
  handleToggle,
  launcherOpenLabel,
  launcherCloseLabel,
  launcherCloseImg,
  launcherOpenImg,
  sendButtonAlt,
  showTimeStamp,
  imagePreview,
  zoomStep,
  handleSubmit,
  showBadge,
  resizable,
  emojis,

  speechContextPhrases,
  speechContextBoosts,
  enableUploadFiles,
  sessionId,
  onUploadFile,
  isLoading,
  color,
  enableSpeechRecognition,
  projectId,
}: Props) {
  const dispatch = useDispatch<Dispatch<ToggleChat | AddUserMessage>>();

  const toggleConversation = () => {
    dispatch(toggleChat());
    handleToggle ? handleToggle(isWidgetOpened()) : (function () {})();
  };

  const handleMessageSubmit = async (userInput: string) => {
    if (!userInput.trim()) {
      return;
    }

    handleSubmit?.(userInput);
    dispatch(addUserMessage(userInput));
    await handleNewUserMessage(userInput);
  };

  const onQuickButtonClicked = (
    event: { preventDefault: () => void },
    value: any,
  ) => {
    event.preventDefault();
    handleQuickButtonClicked?.(value);
  };

  return (
    <WidgetLayout
      onToggleConversation={toggleConversation}
      onSendMessage={handleMessageSubmit}
      onQuickButtonClicked={onQuickButtonClicked}
      title={title}
      speechSynthesisVoiceID={speechSynthesisVoiceID}
      speechSynthesisVoiceType={speechSynthesisVoiceType}
      speechSynthesisVoiceLocale={speechSynthesisVoiceLocale}
      voiceToken={voiceToken}
      titleAvatar={titleAvatar}
      subtitle={subtitle}
      senderPlaceHolder={senderPlaceHolder}
      profileAvatar={profileAvatar}
      profileClientAvatar={profileClientAvatar}
      showCloseButton={showCloseButton}
      fullScreenMode={fullScreenMode}
      autofocus={autofocus}
      customLauncher={customLauncher}
      onTextInputChange={handleTextInputChange}
      chatId={chatId}
      launcherOpenLabel={launcherOpenLabel}
      launcherCloseLabel={launcherCloseLabel}
      launcherCloseImg={launcherCloseImg}
      launcherOpenImg={launcherOpenImg}
      sendButtonAlt={sendButtonAlt}
      showTimeStamp={showTimeStamp}
      imagePreview={imagePreview}
      zoomStep={zoomStep}
      showBadge={showBadge}
      resizable={resizable}
      emojis={emojis}
      speechContextPhrases={speechContextPhrases}
      speechContextBoosts={speechContextBoosts}
      enableUploadFiles={enableUploadFiles}
      sessionId={sessionId}
      onUploadFile={onUploadFile}
      isLoading={isLoading}
      color={color}
      enableSpeechRecognition={enableSpeechRecognition}
      projectId={projectId}
    />
  );
}

export default Widget;
