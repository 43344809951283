import { newIORef } from "fp-ts/IORef";

export const root = document.getElementById("root");

export const resize = (width: number, height: number) => {
  window.parent.postMessage(
    {
      frameWidth: width,
      frameHeight: height
    },
    "*"
  );
};

export enum Size {
  FullScreen = "FullScreen",
  NotFullScreen = "NotFullScreen",
  DoNotChange = "DoNotChange"
}

const status = newIORef(Size.NotFullScreen)();

export const autoResize = (size: Size) => {
  if (!root) {
    throw Error("No react root");
  }

  const current = status.read();

  if (size === Size.FullScreen) {
    window.parent.postMessage(
      {
        fullScreen: true
      },
      "*"
    );
  }

  if (size === Size.NotFullScreen) {
    window.parent.postMessage(
      {
        fullScreen: false
      },
      "*"
    );

    setTimeout(() => {
      resize(root.offsetWidth, root.offsetHeight);
    });
  }

  if (size === Size.DoNotChange) {
    if (current === Size.NotFullScreen) {
      setTimeout(() => {
        resize(root.offsetWidth, root.offsetHeight);
      });
    }
  }

  if (size !== Size.DoNotChange) {
    status.write(size)();
  }
};

export const onResize = () => {
  if (!root) {
    throw Error("No react root");
  }

  root.onresize = () => autoResize(Size.DoNotChange);
};

export const ready = () => {
  window.parent.postMessage(
    {
      ready: true
    },
    "*"
  );
};
