import { useRef, useState, useEffect } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import cn from "classnames";

import Header from "./components/Header";
import Messages from "./components/Messages";
import Sender from "./components/Sender";
import QuickButtons from "./components/QuickButtons";

import { AnyFunction } from "../../../../utils/types";

import "./style.scss";

import * as O from "fp-ts/lib/Option";
import { UUID } from "io-ts-types";
import CustomButtons from "./components/CustomButtons";
import { Subject } from "rxjs";
import { SpeechSynthesisVoiceType } from "src/modules/types";

export interface ISenderRef {
  onSelectEmoji: (event: any) => void;
}

const stopRecognition = new Subject<boolean>();

type Props = {
  title: string;
  subtitle: string;
  senderPlaceHolder: string;
  showCloseButton: boolean;
  disabledInput: boolean;
  autofocus: boolean;
  className: string;
  sendMessage: AnyFunction;
  toggleChat: AnyFunction;
  profileAvatar?: string;
  profileClientAvatar?: string;
  speechSynthesisVoiceID: string;
  speechSynthesisVoiceType: SpeechSynthesisVoiceType;
  speechSynthesisVoiceLocale: string | null;
  voiceToken: string;
  titleAvatar?: string;
  onQuickButtonClicked?: AnyFunction;
  onTextInputChange?: (event: any) => void;
  sendButtonAlt: string;
  showTimeStamp: boolean;
  resizable?: boolean;
  emojis?: boolean;

  speechContextPhrases: string[];
  speechContextBoosts: O.Option<number>[];
  enableUploadFiles: boolean;
  sessionId: O.Option<UUID>;
  onUploadFile?: React.ChangeEventHandler<HTMLInputElement>;
  isLoading: boolean;
  color: string;
  enableSpeechRecognition: boolean;
  projectId: UUID;
};

function Conversation({
  title,
  subtitle,
  senderPlaceHolder,
  showCloseButton,
  disabledInput,
  autofocus,
  className,
  sendMessage,
  toggleChat,
  profileAvatar,
  profileClientAvatar,
  speechSynthesisVoiceID,
  speechSynthesisVoiceType,
  speechSynthesisVoiceLocale,
  voiceToken,
  titleAvatar,
  onQuickButtonClicked,
  onTextInputChange,
  sendButtonAlt,
  showTimeStamp,
  resizable,
  emojis,

  speechContextPhrases,
  speechContextBoosts,
  enableUploadFiles,
  sessionId,
  onUploadFile,
  isLoading,
  color,
  enableSpeechRecognition,
  projectId,
}: Props) {
  const [containerDiv, setContainerDiv] = useState<HTMLElement | null>();
  let startX: any, startWidth: any;

  useEffect(() => {
    const containerDiv = document.getElementById("rcw-conversation-container");
    setContainerDiv(containerDiv);
  }, []);

  const inputRef = useRef<HTMLDivElement>(null!);

  const [dirty, setDirty] = useState(false);

  const initResize = (e: any) => {
    if (resizable) {
      startX = e.clientX;
      if (document.defaultView && containerDiv) {
        startWidth = parseInt(
          document.defaultView.getComputedStyle(containerDiv).width,
        );
        window.addEventListener("mousemove", resize, false);
        window.addEventListener("mouseup", stopResize, false);
      }
    }
  };

  const resize = (e: any) => {
    if (containerDiv) {
      containerDiv.style.width = startWidth - e.clientX + startX + "px";
    }
  };

  const stopResize = (e: any) => {
    window.removeEventListener("mousemove", resize, false);
    window.removeEventListener("mouseup", stopResize, false);
  };

  const [pickerOffset, setOffset] = useState(0);
  const senderRef = useRef<ISenderRef>(null!);
  const [pickerStatus, setPicket] = useState(false);

  const onSelectEmoji = (emoji: any) => {
    senderRef.current?.onSelectEmoji(emoji);
  };

  const togglePicker = () => {
    setPicket((prevPickerStatus) => !prevPickerStatus);
  };

  const handlerSendMsn = async (event: any) => {
    await sendMessage(event);
    if (pickerStatus) setPicket(false);
  };

  return (
    <div
      id="rcw-conversation-container"
      onMouseDown={initResize}
      className={cn("rcw-conversation-container", className)}
      aria-live="polite"
    >
      {resizable && <div className="rcw-conversation-resizer" />}
      <Header
        title={title}
        subtitle={subtitle}
        toggleChat={toggleChat}
        showCloseButton={showCloseButton}
        speechSynthesisVoiceType={speechSynthesisVoiceType}
        titleAvatar={titleAvatar}
        color={color}
      />
      <Messages
        profileAvatar={profileAvatar}
        profileClientAvatar={profileClientAvatar}
        showTimeStamp={showTimeStamp}
        speechSynthesisVoiceID={speechSynthesisVoiceID}
        speechSynthesisVoiceType={speechSynthesisVoiceType}
        speechSynthesisVoiceLocale={speechSynthesisVoiceLocale}
        voiceToken={voiceToken}
        projectId={projectId}
      />
      <QuickButtons onQuickButtonClicked={onQuickButtonClicked} />
      {emojis && pickerStatus && (
        <Picker
          style={{
            position: "absolute",
            bottom: pickerOffset,
            left: "0",
            width: "100%",
          }}
          data={data}
          onEmojiSelect={onSelectEmoji}
        />
      )}
      <Sender
        ref={senderRef}
        sendMessage={handlerSendMsn}
        placeholder={senderPlaceHolder}
        disabledInput={disabledInput}
        autofocus={autofocus}
        onTextInputChange={onTextInputChange}
        buttonAlt={sendButtonAlt}
        onPressEmoji={togglePicker}
        onChangeSize={setOffset}
        emojis={emojis}
        isLoading={isLoading}
        stopRecognition={stopRecognition}
        inputRef={inputRef}
        dirty={dirty}
        setDirty={setDirty}
      />
      <CustomButtons
        onUploadFile={onUploadFile}
        speechContextPhrases={speechContextPhrases}
        enableUploadFiles={enableUploadFiles}
        sessionId={sessionId}
        stopRecognition={stopRecognition}
        inputRef={inputRef}
        sendMessage={handlerSendMsn}
        dirty={dirty}
        setDirty={setDirty}
        color={color}
        enableSpeechRecognition={enableSpeechRecognition}
        projectId={projectId}
      />
    </div>
  );
}

export default Conversation;
