import { ComponentType, useState, useEffect } from "react";
import "./Chatbot.css";
import { init, voidIO, voidTask } from "./modules/handlers";
import * as E from "fp-ts/lib/Either";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import * as TE from "fp-ts/lib/TaskEither";
import { join } from "fp-ts-extras/lib/String";
import { setItem, getItem } from "./modules/localStorage";
import { constant, pipe } from "fp-ts/lib/function";
import {
  OutputPayload,
  Input,
  InputType,
  ReturnProject,
  ProcessedFormResponse,
  SpeechSynthesisVoiceType,
} from "./modules/types";
import ChatbotWidget from "./Widget";
import { autoResize, Size } from "./modules/iframe";
import { UUID } from "io-ts-types";
import config from "./config/settings";
import * as IOE from "fp-ts/lib/IOEither";
import toError from "to-error";
import { SplitFactoryProvider } from "@splitsoftware/splitio-react";
import * as IO from "fp-ts/lib/IO";
import * as t from "io-ts";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useMaybeLogin } from "./modules/maybeLogin";

const sdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: process.env.REACT_APP_SPLITIO_AUTHORIZATION_KEY || "",
    key: process.env.REACT_APP_SPLITIO_KEY || "",
  },
};

const Chatbot: ComponentType<{
  projectId: UUID;
  projectSettings: O.Option<ReturnProject>;
  floating: boolean;
  isMobile: boolean;
  parentLocation: Location;
}> = ({ projectId, floating, isMobile, parentLocation }) => {
  const [messages, onMessages] = useState("");
  const [history, onNewMessages] = useState<Input[]>([]);
  const [debugs, newDebug] = useState<E.Either<unknown, OutputPayload>[]>([]);
  const [settings, setSettings] = useState<
    O.Option<{ project: ReturnProject; voiceToken: string }>
  >(O.none);
  const [fields, onFields] = useState<O.Option<ProcessedFormResponse>>(O.none);
  const [shown, showForm] = useState<boolean>(false);
  // const [domainAllowed, changeDomainAllowed] = useState(true);
  const [speechContextPhrases, onSpeechContextPhrases] = useState<string[]>([]);
  const [speechContextBoosts, onSpeechContextBoosts] = useState<
    O.Option<number>[]
  >([]);
  const [, onWebsocket] = useState<WebSocket>();
  const [sessionId, onSessionId] = useState<O.Option<UUID>>(O.none);

  const ioOnMessages = (messages: string) =>
    IOE.tryCatch(() => onMessages(messages), toError);

  if (
    debugs &&
    messages &&
    String(onSpeechContextPhrases) &&
    String(onSpeechContextBoosts)
  ) {
    // legacy stuff
  }

  const getAccessTokenSilentlyWithoutLogin = useMaybeLogin();

  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const runReplay = pipe(
      getItem("replay"),
      IO.map((r) => t.string.decode(r)),
      IOE.mapLeft(toError),
      IOE.chain((messages) => ioOnMessages(messages)),
      IOE.fold(constant(voidIO), constant(voidIO)),
    );

    runReplay();

    pipe(
      TE.fromTask(getAccessTokenSilentlyWithoutLogin),
      TE.chain((token) => {
        return init(
          projectId,
          newDebug,
          floating,
          onFields,
          showForm,
          parentLocation,
          onNewMessage,
          token,
        );
      }),
      TE.chain((a) => {
        var { response, voiceToken } = a;
        setSettings(O.some({ project: response.project, voiceToken }));
        return TE.of(a);
      }),
      TE.fold(
        (e) => {
          console.error(e);
          // TODO: check 401
          if (!isAuthenticated) {
            loginWithRedirect();
          }
          // changeDomainAllowed(false);
          return voidTask;
        },
        (a) => {
          const { websocket, response } = a;

          onWebsocket(websocket);

          onSessionId(O.some(response.sessionId));

          // changeDomainAllowed(true);

          if (!floating) {
            autoResize(Size.DoNotChange);
          }

          return voidTask;
        },
      ),
    )();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, isLoading]);

  const onNewMessage = (sessionId: UUID, input: Input) => {
    // const i = { action: "sendMessage", message: input.value };
    // websocket.send(JSON.stringify(i))
    return TE.of(undefined);
  };

  const newHistory = (allHistory: Input[]) => {
    const allMessages = pipe(
      allHistory,
      A.filterMap((m) =>
        m.type === InputType.Text ? O.some(m.value) : O.none,
      ),
      join("\n"),
    );

    const runReplay = pipe(
      setItem("replay", allMessages),
      IOE.fold(constant(voidIO), constant(voidIO)),
    );

    runReplay();

    return pipe(allHistory, onNewMessages);
  };

  const onHistory = (newMessages: Input) => {
    const allHistory = A.snoc(history, newMessages);

    return newHistory(allHistory);
  };

  // const onHistoryMessages = (newInputs: Input[]) => {
  //   const allHistory = M.concat(history, newInputs);

  //   return newHistory(allHistory);
  // };

  return pipe(
    settings,
    O.fold(
      () => ({
        project: {
          widgetTitle: "Loading",
          widgetColor: "rgb(80, 227, 194)",
          widgetDescription: "Please wait",
          fallbackMessage: "",
          domains: [""],
          enableSpeechRecognition: true,
          speechSynthesisVoiceType: SpeechSynthesisVoiceType.VoiceOnly,
          speechSynthesisVoiceID: "",
          speechSynthesisVoiceLocale: "",
          enableUploadFiles: false,
        },
        voiceToken: "",
      }),
      (s) => s,
    ),
    (s: { project: ReturnProject; voiceToken: string }) => (
      <div className="Chatbot">
        {config.debugger
          ? // <Debugger
            //   onMessages={onMessages}
            //   messages={messages}
            //   onHistory={onHistoryMessages}
            //   newDebug={newDebug}
            //   history={history}
            //   debugs={debugs}
            //   fallbackMessage={s.project.fallbackMessage}
            //   onFields={onFields}
            //   showForm={showForm}
            //   floating={floating}
            //   onSpeechContextPhrases={onSpeechContextPhrases}
            //   onSpeechContextBoosts={onSpeechContextBoosts}
            //   projectId={projectId}
            //   onNewMessage={onNewMessage}
            //   sessionId={sessionId}
            //   parentLocation={parentLocation}
            //   speechSynthesisVoiceType={s.project.speechSynthesisVoiceType}
            //   speechSynthesisVoiceID={s.project.speechSynthesisVoiceID}
            //   voiceToken={s.voiceToken}
            // />
            null
          : null}

        <ChatbotWidget
          onHistory={onHistory}
          newDebug={newDebug}
          settings={s.project}
          fields={fields}
          fallbackMessage={s.project.fallbackMessage}
          onFields={onFields}
          showForm={showForm}
          shown={shown}
          floating={floating}
          isMobile={isMobile}
          speechContextPhrases={speechContextPhrases}
          speechContextBoosts={speechContextBoosts}
          projectId={projectId}
          onNewMessage={onNewMessage}
          sessionId={sessionId}
          parentLocation={parentLocation}
          enableSpeechRecognition={s.project.enableSpeechRecognition}
          speechSynthesisVoiceType={s.project.speechSynthesisVoiceType}
          speechSynthesisVoiceID={s.project.speechSynthesisVoiceID}
          speechSynthesisVoiceLocale={s.project.speechSynthesisVoiceLocale}
          enableUploadFiles={s.project.enableUploadFiles}
          titleAvatar={
            s.project.widgetTitle === "Chris' PAA"
              ? "https://create-images-results.d-id.com/DefaultPresenters/William_m/image.jpeg"
              : s.project.widgetTitle === "Emma"
                ? "https://carever-demo.s3.ap-southeast-2.amazonaws.com/avatars/CompressJPEG+Qing+Qing-PhotoRoom.png"
                : "https://create-images-results.d-id.com/DefaultPresenters/Zivva_f/image.png"
          }
          voiceToken={s.voiceToken}
        />
      </div>
    ),
  );
};

enum ErrorCode {
  EmailNotVerified = "email_not_verified",
}

function GoBackButton() {
  return (
    <button
      onClick={() => (window.location.href = "/")}
      style={{
        backgroundColor: "#04AA6D",
        padding: "15px 32px",
      }}
    >
      Go back
    </button>
  );
}

function LogoutButton() {
  const { logout } = useAuth0();

  return (
    <button
      onClick={() => {
        logout({ logoutParams: { returnTo: window.location.origin } });
      }}
      style={{
        backgroundColor: "red",
        padding: "15px 32px",
      }}
    >
      Log out
    </button>
  );
}

function Auth0ErrorHandler({ children }: { children: React.ReactNode }) {
  const { error } = useAuth0();

  if (error) {
    try {
      const message = JSON.parse(error.message);
      switch (message.code) {
        case ErrorCode.EmailNotVerified:
          return (
            <div>
              <h1>Verify your email</h1>
              <p>Oops... {message.message}</p>
              <p>{message.innerError}</p>
              <p>
                <button
                  onClick={() => (window.location.href = "/")}
                  title="Click here after you have verified your email"
                  style={{
                    backgroundColor: "#04AA6D",
                    padding: "15px 32px",
                  }}
                >
                  I have verified my email
                </button>
              </p>
              <p>
                <LogoutButton />
              </p>
            </div>
          );
        default:
          return (
            <div>
              <h1>Error</h1>
              <p>Oops... {message.message}</p>
              <p>{message.innerError}</p>
              <p>
                <GoBackButton />
              </p>
              <p>
                <LogoutButton />
              </p>
            </div>
          );
      }
    } catch {
      return (
        <div>
          <h1>Error</h1>
          <p>Oops... {error.message}</p>
          <p>
            <GoBackButton />
          </p>
          <p>
            <LogoutButton />
          </p>
        </div>
      );
    }
  }

  return children;
}

const Wrapper: ComponentType<{
  projectId: UUID;
  projectSettings: O.Option<ReturnProject>;
  floating: boolean;
  isMobile: boolean;
  parentLocation: Location;
}> = (props) => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_ISSUER_BASE_URL || ""}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "openid profile email admin",
      }}
    >
      <SplitFactoryProvider config={sdkConfig}>
        <Auth0ErrorHandler>
          <Chatbot {...props} />
        </Auth0ErrorHandler>
      </SplitFactoryProvider>
    </Auth0Provider>
  );
};

export default Wrapper;
