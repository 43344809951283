import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";

import { GlobalState } from "../../store/types";
import { AnyFunction } from "../../utils/types";
import { openFullscreenPreview } from "../../store/actions";

import Conversation from "./components/Conversation";
import Launcher from "./components/Launcher";
import FullScreenPreview from "./components/FullScreenPreview";

import "./style.scss";

import * as O from "fp-ts/lib/Option";
import { UUID } from "io-ts-types";
import { SpeechSynthesisVoiceType } from "src/modules/types";
import { Dispatch } from "redux";
import { FullscreenPreviewActions } from "../../store/actions/types";

type Props = {
  title: string;
  speechSynthesisVoiceID: string;
  speechSynthesisVoiceType: SpeechSynthesisVoiceType;
  speechSynthesisVoiceLocale: string | null;
  voiceToken: string;
  titleAvatar?: string;
  subtitle: string;
  onSendMessage: AnyFunction;
  onToggleConversation: AnyFunction;
  senderPlaceHolder: string;
  onQuickButtonClicked: AnyFunction;
  profileAvatar?: string;
  profileClientAvatar?: string;
  showCloseButton: boolean;
  fullScreenMode: boolean;
  autofocus: boolean;
  customLauncher?: AnyFunction;
  onTextInputChange?: (event: any) => void;
  chatId: string;
  launcherOpenLabel: string;
  launcherCloseLabel: string;
  launcherCloseImg: string;
  launcherOpenImg: string;
  sendButtonAlt: string;
  showTimeStamp: boolean;
  imagePreview?: boolean;
  zoomStep?: number;
  showBadge?: boolean;
  resizable?: boolean;
  emojis?: boolean;

  speechContextPhrases: string[];
  speechContextBoosts: O.Option<number>[];
  enableUploadFiles: boolean;
  sessionId: O.Option<UUID>;
  onUploadFile?: React.ChangeEventHandler<HTMLInputElement>;
  isLoading: boolean;
  color: string;
  enableSpeechRecognition: boolean;
  projectId: UUID;
};

function WidgetLayout({
  title,
  speechSynthesisVoiceID,
  speechSynthesisVoiceType,
  speechSynthesisVoiceLocale,
  voiceToken,
  titleAvatar,
  subtitle,
  onSendMessage,
  onToggleConversation,
  senderPlaceHolder,
  onQuickButtonClicked,
  profileAvatar,
  profileClientAvatar,
  showCloseButton,
  fullScreenMode,
  autofocus,
  customLauncher,
  onTextInputChange,
  chatId,
  launcherOpenLabel,
  launcherCloseLabel,
  launcherCloseImg,
  launcherOpenImg,
  sendButtonAlt,
  showTimeStamp,
  imagePreview,
  zoomStep,
  showBadge,
  resizable,
  emojis,

  speechContextPhrases,
  speechContextBoosts,
  enableUploadFiles,
  sessionId,
  onUploadFile,
  isLoading,
  color,
  enableSpeechRecognition,
  projectId,
}: Props) {
  const dispatch = useDispatch<Dispatch<FullscreenPreviewActions>>();
  const { dissableInput, showChat, visible } = useSelector(
    (state: GlobalState) => ({
      showChat: state.behavior.showChat,
      dissableInput: state.behavior.disabledInput,
      visible: state.preview.visible,
    }),
  );

  const messageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (showChat) {
      messageRef.current = document.getElementById(
        "messages",
      ) as HTMLDivElement;
    }
    return () => {
      messageRef.current = null;
    };
  }, [showChat]);

  const eventHandle = (evt: any) => {
    if (evt.target && evt.target.className === "rcw-message-img") {
      const { src, alt, naturalWidth, naturalHeight } =
        evt.target as HTMLImageElement;
      const obj = {
        src: src,
        alt: alt,
        width: naturalWidth,
        height: naturalHeight,
      };
      dispatch(openFullscreenPreview(obj));
    }
  };

  /**
   * Previewer needs to prevent body scroll behavior when fullScreenMode is true
   */
  useEffect(() => {
    const target = messageRef?.current;
    if (imagePreview && showChat) {
      target?.addEventListener("click", eventHandle, false);
    }

    return () => {
      target?.removeEventListener("click", eventHandle);
    };
  }, [imagePreview, showChat]);

  useEffect(() => {
    document.body.setAttribute(
      "style",
      `overflow: ${visible || fullScreenMode ? "hidden" : "auto"}`,
    );
  }, [fullScreenMode, visible]);

  return (
    <div
      className={cn("rcw-widget-container", {
        "rcw-full-screen": fullScreenMode,
        "rcw-previewer": imagePreview,
        "rcw-close-widget-container ": !showChat,
      })}
    >
      {showChat && (
        <Conversation
          title={title}
          subtitle={subtitle}
          sendMessage={onSendMessage}
          senderPlaceHolder={senderPlaceHolder}
          profileAvatar={profileAvatar}
          profileClientAvatar={profileClientAvatar}
          toggleChat={onToggleConversation}
          showCloseButton={showCloseButton}
          disabledInput={dissableInput}
          autofocus={autofocus}
          speechSynthesisVoiceID={speechSynthesisVoiceID}
          speechSynthesisVoiceType={speechSynthesisVoiceType}
          speechSynthesisVoiceLocale={speechSynthesisVoiceLocale}
          voiceToken={voiceToken}
          titleAvatar={titleAvatar}
          className={showChat ? "active" : "hidden"}
          onQuickButtonClicked={onQuickButtonClicked}
          onTextInputChange={onTextInputChange}
          sendButtonAlt={sendButtonAlt}
          showTimeStamp={showTimeStamp}
          resizable={resizable}
          emojis={emojis}
          speechContextPhrases={speechContextPhrases}
          speechContextBoosts={speechContextBoosts}
          enableUploadFiles={enableUploadFiles}
          sessionId={sessionId}
          onUploadFile={onUploadFile}
          isLoading={isLoading}
          color={color}
          enableSpeechRecognition={enableSpeechRecognition}
          projectId={projectId}
        />
      )}
      {customLauncher
        ? customLauncher(onToggleConversation)
        : !fullScreenMode && (
            <Launcher
              toggle={onToggleConversation}
              chatId={chatId}
              openLabel={launcherOpenLabel}
              closeLabel={launcherCloseLabel}
              closeImg={launcherCloseImg}
              openImg={launcherOpenImg}
              showBadge={showBadge}
              color={color}
            />
          )}
      {imagePreview && (
        <FullScreenPreview
          fullScreenMode={fullScreenMode}
          zoomStep={zoomStep}
        />
      )}
    </div>
  );
}

export default WidgetLayout;
