import axios, { AxiosResponse } from "axios";
import settings from "../config/settings";
import * as TE from "fp-ts/lib/TaskEither";
import urlJoin from "url-join";
import teTryCatch from "./teTryCatch";

export const instance = axios.create({
  baseURL: urlJoin(settings.engineDomain, settings.enginePath),
});

export const post = (
  path: string,
  data: Object,
  token: string,
): TE.TaskEither<Error, AxiosResponse<unknown>> =>
  teTryCatch(() =>
    instance.post<unknown>(path, data, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }),
  );

export const get = (
  path: string,
  token: string,
): TE.TaskEither<Error, AxiosResponse<unknown>> =>
  teTryCatch(() =>
    instance.get<unknown>(path, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }),
  );
