import { format } from "date-fns/format";
import markdownIt from "markdown-it";
import markdownItSup from "markdown-it-sup";
import markdownItSanitizer from "markdown-it-sanitizer";
import markdownItClass from "@toycode/markdown-it-class";
import markdownItLinkAttributes from "markdown-it-link-attributes";

import { MessageTypes } from "../../../../../../../../store/types";

import "./styles.scss";

import markdownItReplaceLink from "markdown-it-replace-link";
import emoji from "markdown-it-emoji";
import speak from "src/modules/speak";
import { SpeechSynthesisVoiceType } from "src/modules/types";
import { UUID } from "io-ts-types";

type Props = {
  message: MessageTypes;
  showTimeStamp: boolean;
  projectId: UUID;
  speechSynthesisVoiceType: SpeechSynthesisVoiceType;
  speechSynthesisVoiceID: string;
  speechSynthesisVoiceLocale: string;
  authorizationToken: string;
};

const opt: any = {
  replaceLink: function (link: string) {
    if (!link.startsWith("http")) {
      return "https://" + link;
    }

    return link;
  },
};

export const toHTML = (text: string) =>
  markdownIt(opt)
    .use(markdownItClass, {
      img: ["rcw-message-img"],
    })
    .use(markdownItSup)
    .use(markdownItSanitizer)
    .use(markdownItLinkAttributes, {
      attrs: { target: "_blank", rel: "noopener" },
    })
    .render(text);

export const toPlainText = (html: string) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.innerText;
};

function Message({
  message,
  showTimeStamp,
  speechSynthesisVoiceType,
  speechSynthesisVoiceID,
  speechSynthesisVoiceLocale,
  authorizationToken,
  projectId,
}: Props) {
  const sanitizedHTML = toHTML(message.text);
  const plainText = toPlainText(sanitizedHTML);

  return (
    <div className={`rcw-${message.sender}`}>
      <button
        className="rcw-message-text"
        onClick={() => {
          if (speechSynthesisVoiceType === SpeechSynthesisVoiceType.VoiceOnly) {
            speak(
              plainText,
              speechSynthesisVoiceID,
              speechSynthesisVoiceLocale,
              authorizationToken,
              projectId,
            );
          }
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedHTML.replace(/\n$/, "") }}
      />
      {showTimeStamp && (
        <span className="rcw-timestamp">
          {format(message.timestamp, "hh:mm")}
        </span>
      )}
    </div>
  );
}

export default Message;
