import React, { ComponentType } from "react";
import "./ErrorResponse.css";

const ErrorResponse: ComponentType<{ message: string }> = ({ message }) => (
  <div className="ErrorResponse">
    <span>{message}</span>
  </div>
);

export default ErrorResponse;
