import * as t from "io-ts";
import {
  DateFromISOString,
  JsonFromString,
  nonEmptyArray,
  optionFromNullable,
  UUID,
} from "io-ts-types";
import { eitherFromUnion } from "fp-ts-extras/lib/Either";
import * as TE from "fp-ts/lib/TaskEither";
import * as Type from "io-ts/lib/Type";

export enum InputType {
  Text = "TEXT",
  Form = "FORM",
}

const TextInputT = t.type({
  value: t.string,
  type: t.literal(InputType.Text),
});
export type TextInput = t.TypeOf<typeof TextInputT>;

const FormInputValueT = t.array(
  t.type({
    key: t.string,
    value: t.string,
  }),
);
export type FormInputValue = t.TypeOf<typeof FormInputValueT>;

const FormInputT = t.type({
  value: FormInputValueT,
  type: t.literal(InputType.Form),
  responseId: UUID,
});
export type FormInput = t.TypeOf<typeof FormInputT>;

export const InputT = t.union([TextInputT, FormInputT]);
export type Input = t.TypeOf<typeof InputT>;

export const ResponseIdsT = t.array(UUID);
export type ResponseIds = t.TypeOf<typeof ResponseIdsT>;

const ValueT = eitherFromUnion(t.number, t.string);

const OperatorT = t.keyof({
  CONTAINS: null,
  ENDSWITH: null,
  EQ: null,
  GT: null,
  GTE: null,
  KNOWN: null,
  LT: null,
  LTE: null,
  NE: null,
  STARTSWITH: null,
  UNKNOWN: null,
});

export enum Comparer {
  Value = "VALUE",
  OtherEntityName = "OTHER_ENTITY_NAME",
}

const IntentNameT = t.string;
export type IntentName = t.TypeOf<typeof IntentNameT>;

export const EntityT = t.type({
  entityName: t.string,
  value: ValueT,
});

const LanguageCodeT = t.string;

export const IntentT = t.type({
  entities: t.array(EntityT),
  name: IntentNameT,
  confidence: t.number,
  languageCode: LanguageCodeT,
});
export type Intent = t.TypeOf<typeof IntentT>;

const StringOrNumberT = t.union([t.string, t.number]);

const SaveEntityWithEntityId = t.type({
  entityNameId: UUID,
  value: StringOrNumberT,
});
export type SaveEntity = t.TypeOf<typeof SaveEntityWithEntityId>;

const SaveIntentWithEntityIdsT = t.type({
  entities: t.array(SaveEntityWithEntityId),
  name: IntentNameT,
  confidence: t.number,
  languageCode: LanguageCodeT,
});
export type SaveIntentWithEntityIds = t.TypeOf<typeof SaveIntentWithEntityIdsT>;

const GetEntityWithEntityId = t.type({
  entityNameId: UUID,
  value: ValueT,
});
export type Entity = t.TypeOf<typeof GetEntityWithEntityId>;

const GetIntentWithEntityIdsT = t.type({
  entities: t.array(GetEntityWithEntityId),
  name: IntentNameT,
  confidence: t.number,
  languageCode: LanguageCodeT,
});
export type GetIntentWithEntityIds = t.TypeOf<typeof GetIntentWithEntityIdsT>;

export const GetIntentsT = t.array(GetIntentWithEntityIdsT);

export const IntentIdT = t.type({
  id: UUID,
});

export enum ResponsePartType {
  Rte = "RTE",
  OtherEntityName = "OTHER_ENTITY_NAME",
}

const RteResolutionPartT = t.type({
  type: t.literal(ResponsePartType.Rte),
  text: t.string,
});

const OtherEntityNameResolutionPartT = t.type({
  type: t.literal(ResponsePartType.OtherEntityName),
  entityNameId: UUID,
  path: optionFromNullable(t.string),
});

const ResolutionPartT = t.union([
  RteResolutionPartT,
  OtherEntityNameResolutionPartT,
]);

const ResolutionPartsT = t.array(ResolutionPartT);

export enum ResponseType {
  Rte = "RTE",
  Form = "FORM",
  Handover = "HANDOVER",
  Email = "EMAIL",
  Video = "VIDEO",
  Social = "SOCIAL",
  Webhook = "WEBHOOK",
  QuickReply = "QUICK_REPLY",
}

const ResponseTypeT = t.keyof({
  [ResponseType.Rte]: null,
  [ResponseType.Form]: null,
  [ResponseType.Handover]: null,
  [ResponseType.Video]: null,
  [ResponseType.Social]: null,
  [ResponseType.Webhook]: null,
  [ResponseType.QuickReply]: null,
});

const RteResponseResolutionT = t.type({
  parts: ResolutionPartsT,
});

const SpeechContextT = t.type({
  phrases: t.array(t.string),
  boost: optionFromNullable(t.number),
});
export type SpeechContext = t.TypeOf<typeof SpeechContextT>;

const SpeechContextsT = t.array(SpeechContextT);
export type SpeechContexts = t.TypeOf<typeof SpeechContextsT>;

const RteResponseT = t.type({
  id: UUID,
  order: t.number,
  resolution: RteResponseResolutionT,
  type: t.literal(ResponseType.Rte),
  speechContexts: optionFromNullable(SpeechContextsT),
});
export type RteResponse = t.TypeOf<typeof RteResponseT>;

const QuickReplyResponseT = t.type({
  id: UUID,
  order: t.number,
  resolution: RteResponseResolutionT,
  type: t.literal(ResponseType.QuickReply),
  speechContexts: optionFromNullable(SpeechContextsT),
});
export type QuickReplyResponse = t.TypeOf<typeof QuickReplyResponseT>;

const RteQuickReplyResponseT = t.union([RteResponseT, QuickReplyResponseT]);
export type RteQuickReplyResponse = t.TypeOf<typeof RteQuickReplyResponseT>;

const FormResponseResolutionT = t.type({
  fields: t.array(
    t.type({
      entityNameId: t.string,
      displayName: t.string,
    }),
  ),
});
export type FormResponseResolution = t.TypeOf<typeof FormResponseResolutionT>;

export enum WebhookMethod {
  Get = "GET",
  Post = "POST",
}

const WebhookMethodT = t.keyof({
  [WebhookMethod.Get]: null,
  [WebhookMethod.Post]: null,
});

const WebhookHeaderT = t.type({
  name: t.string,
  value: t.string,
});

const HeaderListT = t.array(WebhookHeaderT);
export type HeaderList = t.TypeOf<typeof HeaderListT>;

const WebhookDataT = t.type({
  url: t.string,
  entityNameId: UUID,
  method: WebhookMethodT,
  headers: HeaderListT,
});
export type WebhookData = t.TypeOf<typeof WebhookDataT>;

const WebhookResponseResolutionT = t.type({
  webhook: WebhookDataT,
});

const FormResponseT = t.type({
  id: UUID,
  order: t.number,
  resolution: FormResponseResolutionT,
  type: t.literal(ResponseType.Form),
  speechContexts: optionFromNullable(SpeechContextsT),
});

const HandoverResponseResponseT = t.type({
  id: UUID,
  order: t.number,
  type: t.literal(ResponseType.Handover),
  speechContexts: optionFromNullable(SpeechContextsT),
});

const ValueConditionT = t.type({
  comparer: t.literal(Comparer.Value),
  operator: OperatorT,
  value: ValueT,
});

const WebhookResponseT = t.type({
  id: UUID,
  order: t.number,
  resolution: WebhookResponseResolutionT,
  type: t.literal(ResponseType.Webhook),
  speechContexts: optionFromNullable(SpeechContextsT),
});

export const ResponseT = t.union([
  RteResponseT,
  QuickReplyResponseT,
  FormResponseT,
  HandoverResponseResponseT,
  WebhookResponseT,
]);
export type Response = t.TypeOf<typeof ResponseT>;

export const ResponsesT = t.array(ResponseT);
export type Responses = t.TypeOf<typeof ResponsesT>;

export const NonEmptyResponsesT = nonEmptyArray(ResponseT);

const OtherEntityNameConditionT = t.type({
  comparer: t.literal(Comparer.OtherEntityName),
  operator: OperatorT,
  entityNameId: UUID,
});

const ConditionT = t.union([OtherEntityNameConditionT, ValueConditionT]);

const ExpressionT = t.type({
  condition: ConditionT,
  entityNameId: UUID,
});

const ExpressionsT = t.array(ExpressionT);

const QueryWithResponseMightMissT = t.type({
  expressions: ExpressionsT,
  id: UUID,
  intent: t.string,
  responses: t.array(optionFromNullable(UUID)),
});
export const QueriesWithResponseMightMissT = t.array(
  QueryWithResponseMightMissT,
);

const QueryT = t.type({
  expressions: ExpressionsT,
  id: UUID,
  intent: t.string,
  responses: ResponseIdsT,
});
export type Query = t.TypeOf<typeof QueryT>;

export const QueriesT = t.array(QueryT);
type Queries = t.TypeOf<typeof QueriesT>;

export type Condition = t.TypeOf<typeof ConditionT>;

export interface QueryWithIsPriority extends Query {
  readonly priority: boolean;
}

export enum ContextOperator {
  Contains = "CONTAINS",
  Endswith = "ENDSWITH",
  Eq = "EQ",
  Gt = "GT",
  Gte = "GTE",
  Lt = "LT",
  Lte = "LTE",
  Ne = "NE",
  Startswith = "STARTSWITH",
}

const ContextOperatorT = t.keyof({
  [ContextOperator.Contains]: null,
  [ContextOperator.Endswith]: null,
  [ContextOperator.Eq]: null,
  [ContextOperator.Gt]: null,
  [ContextOperator.Gte]: null,
  [ContextOperator.Lt]: null,
  [ContextOperator.Lte]: null,
  [ContextOperator.Ne]: null,
  [ContextOperator.Startswith]: null,
});

export const ContextT = t.type({
  entityNameId: UUID,
  operator: ContextOperatorT,
  value: ValueT,
});
export type Context = t.TypeOf<typeof ContextT>;
export const ContextListT = t.array(ContextT);
export type ContextList = t.TypeOf<typeof ContextListT>;

const EngineContextT = t.type({
  entityNameId: UUID,
  operator: ContextOperatorT,
  value: StringOrNumberT,
});
export const EngineContextListT = t.array(EngineContextT);
export type EngineContextList = t.TypeOf<typeof EngineContextListT>;

export const QuickReplyT = t.type({
  key: t.string,
  display: t.string,
});
export type QuickReply = t.TypeOf<typeof QuickReplyT>;

export const PayloadT = t.type({
  input: InputT,
  quickReply: Type.nullable(QuickReplyT),
  sessionId: UUID,
  meta: t.unknown,
});
export type Payload = t.TypeOf<typeof PayloadT>;

export const HeadersT = t.type({
  authorization: optionFromNullable(t.string),
});

export const StartPayloadT = t.type({
  projectId: UUID,
  meta: t.unknown,
});
export type StartPayload = t.TypeOf<typeof StartPayloadT>;

export const EntityNameMappingsT = nonEmptyArray(
  t.type({
    fromEntityName: t.string,
    fromEntityNameId: UUID,
    id: UUID,
    name: t.string,
  }),
);

export enum IntentType {
  Standard = "STANDARD",
  Generic = "GENERIC",
}

const ProcessedOtherEntityNameResolutionPartT = t.type({
  text: t.string,
  type: t.literal(ResponsePartType.OtherEntityName),
  entityNameId: UUID,
});

const ResolutionPartWithTextT = t.union([
  RteResolutionPartT,
  ProcessedOtherEntityNameResolutionPartT,
]);
export type ResolutionPartWithText = t.TypeOf<typeof ResolutionPartWithTextT>;

const ResolutionPartsWithTextT = t.array(ResolutionPartWithTextT);
export type ResolutionPartsWithText = t.TypeOf<typeof ResolutionPartsWithTextT>;

const ResolutionPartsWithTextResolutionT = ResolutionPartsWithTextT;

const QuestionIntentT = t.type({
  intentId: UUID,
  intentType: t.keyof({
    [IntentType.Standard]: null,
    [IntentType.Generic]: null,
  }),
  responseId: UUID,
});
export type QuestionIntent = t.TypeOf<typeof QuestionIntentT>;

export const QuestionIntentListT = t.array(QuestionIntentT);

export type QuestionIntentList = t.TypeOf<typeof QuestionIntentListT>;

const RteResponseWithTextT = t.type({
  id: UUID,
  resolution: ResolutionPartsWithTextResolutionT,
  type: t.literal(ResponseType.Rte),
});
export type RteResponseWithText = t.TypeOf<typeof RteResponseWithTextT>;

const ProcessedQuickReplyResponseT = t.type({
  type: t.literal(ResponseType.QuickReply),
  quickReply: QuickReplyT,
});
export type ProcessedQuickReplyResponse = t.TypeOf<
  typeof ProcessedQuickReplyResponseT
>;

export enum FormFieldType {
  Input = "INPUT",
  TextArea = "TEXT_AREA",
}

const FormFieldTypeT = t.keyof({
  [FormFieldType.Input]: null,
  [FormFieldType.TextArea]: null,
});

const ProcessedFormResolutionT = t.array(
  t.type({
    entityNameId: t.string,
    displayName: t.string,
    required: t.boolean,
    type: FormFieldTypeT,
  }),
);

const ProcessedFormResponseT = t.type({
  id: UUID,
  resolution: ProcessedFormResolutionT,
  type: t.literal(ResponseType.Form),
});
export type ProcessedFormResponse = t.TypeOf<typeof ProcessedFormResponseT>;

const ProcessedHandoverResponseResponseT = t.type({
  id: UUID,
  type: t.literal(ResponseType.Handover),
});

const ProcessedWebhookResponseT = t.type({
  id: UUID,
  type: t.literal(ResponseType.Webhook),
});

const ResponseWithTextT = t.union([
  RteResponseWithTextT,
  ProcessedQuickReplyResponseT,
  ProcessedFormResponseT,
  ProcessedHandoverResponseResponseT,
  ProcessedWebhookResponseT,
]);

export type ResponseWithText = t.TypeOf<typeof ResponseWithTextT>;

const ResponsesWithTextT = t.array(ResponseWithTextT);
export type ResponsesWithText = t.TypeOf<typeof ResponsesWithTextT>;

const OptionalJsonSpeechContextsT = optionFromNullable(
  t.string.pipe(JsonFromString).pipe(SpeechContextsT, "JsonSpeechContexts"),
);

const DmsRteResponseT = t.type({
  responseId: UUID,
  order: t.number,
  resolution: RteResponseResolutionT,
  type: t.literal(ResponseType.Rte),
  speechContexts: OptionalJsonSpeechContextsT,
});

const DmsQuickReplyResponseT = t.type({
  responseId: UUID,
  order: t.number,
  resolution: RteResponseResolutionT,
  type: t.literal(ResponseType.QuickReply),
  speechContexts: OptionalJsonSpeechContextsT,
});

const DmsFormResponseT = t.type({
  responseId: UUID,
  order: t.number,
  resolution: FormResponseResolutionT,
  type: t.literal(ResponseType.Form),
  speechContexts: OptionalJsonSpeechContextsT,
});

const DmsHandoverResponseResponseT = t.type({
  responseId: UUID,
  order: t.number,
  type: t.literal(ResponseType.Handover),
  speechContexts: OptionalJsonSpeechContextsT,
});

const DmsResponseT = t.union([
  DmsRteResponseT,
  DmsQuickReplyResponseT,
  DmsFormResponseT,
  DmsHandoverResponseResponseT,
]);
export type DmsResponse = t.TypeOf<typeof DmsResponseT>;

export enum SpeechSynthesisVoiceType {
  None = "none",
  VoiceOnly = "voiceOnly",
  Avatar = "avatar",
}

const SpeechSynthesisVoiceTypeT = t.keyof({
  [SpeechSynthesisVoiceType.None]: null,
  [SpeechSynthesisVoiceType.VoiceOnly]: null,
  [SpeechSynthesisVoiceType.Avatar]: null,
});

export const ProjectT = t.type({
  projectId: UUID,
  widgetTitle: optionFromNullable(t.string),
  widgetColor: optionFromNullable(t.string),
  widgetDescription: optionFromNullable(t.string),
  fallbackMessage: optionFromNullable(t.string),
  greetingResponses: t.array(DmsResponseT),
  enquiries: optionFromNullable(t.array(t.string)),
  apiKey: optionFromNullable(t.string),
  domains: optionFromNullable(t.array(t.string)),
  businessTimeStart: optionFromNullable(DateFromISOString),
  businessTimeEnd: optionFromNullable(DateFromISOString),
  businessTimezone: t.string,
  businessEmail: optionFromNullable(t.string),
});
export type Project = t.TypeOf<typeof ProjectT>;

const ReturnProjectT = t.type({
  widgetTitle: t.string,
  widgetColor: t.string,
  widgetDescription: t.string,
  fallbackMessage: t.string,
  domains: t.array(t.string),
  enableSpeechRecognition: t.boolean,
  speechSynthesisVoiceType: SpeechSynthesisVoiceTypeT,
  speechSynthesisVoiceID: t.string,
  speechSynthesisVoiceLocale: Type.nullable(t.string),
  enableUploadFiles: t.boolean,
});
export type ReturnProject = t.TypeOf<typeof ReturnProjectT>;

export const NonEmptyResponseWithTextT = nonEmptyArray(ResponseWithTextT);
export type NonEmptyResponseWithText = t.TypeOf<
  typeof NonEmptyResponseWithTextT
>;

export const StartChatResponseDataT = t.type({
  sessionId: UUID,
  project: ReturnProjectT,
  responses: NonEmptyResponseWithTextT,
});
export type StartChatResponseData = t.TypeOf<typeof StartChatResponseDataT>;

export const AxiosErrorResponseT = t.type({
  data: t.string,
  config: t.string,
});
export type AxiosErrorResponse = t.TypeOf<typeof AxiosErrorResponseT>;

const FailureChatResponseDataT = t.partial({
  context: EngineContextListT,
  intent: SaveIntentWithEntityIdsT,
  isAdmin: t.boolean,
  message: t.string,
  name: t.string,
  queries: QueriesT,
  questions: t.array(UUID),
  responses: ResponsesWithTextT,
  stack: t.string,
  axiosErrorResponse: AxiosErrorResponseT,
});
export type FailureEventOutput = t.TypeOf<typeof FailureChatResponseDataT>;

const SuccessChatResponseDataT = t.type({
  context: EngineContextListT,
  intent: SaveIntentWithEntityIdsT,
  isAdmin: t.boolean,
  queries: QueriesT,
  questions: t.array(UUID),
  responses: ResponsesWithTextT,
});

// SuccessChatResponseDataT should be devided into auth and non auth
const ChatResponseDataT = t.union([
  FailureChatResponseDataT,
  SuccessChatResponseDataT,
]);

export interface InputEventPayload {
  readonly input: Input;
  readonly projectId: UUID;
  readonly sessionStatus: SessionStatus;
  readonly sessionId: UUID;
  readonly requestId: UUID;
}

export enum SessionStatus {
  Chatbot = "CHATBOT",
  Handover = "HANDOVER",
}

const SessionStatusT = t.keyof({
  [SessionStatus.Chatbot]: null,
  [SessionStatus.Handover]: null,
});

export const ProjectIdSessionStatusT = t.type({
  projectId: UUID,
  sessionStatus: SessionStatusT,
});
export type ProjectIdSessionStatus = t.TypeOf<typeof ProjectIdSessionStatusT>;

export const SlackChannelT = t.type({
  sessionId: UUID,
  workspaceId: UUID,
  channelId: t.string,
});
export type SlackChannel = t.TypeOf<typeof SlackChannelT>;

const GeneralChatResponseDataT = t.object;

export const OutputEventPayloadT = t.type({
  output: GeneralChatResponseDataT,
  projectId: UUID,
  requestId: UUID,
  sessionId: UUID,
  sessionStatus: SessionStatusT,
});
export type OutputEventPayload = t.TypeOf<typeof OutputEventPayloadT>;

export const FailureRuntimeOutputEventPayloadT = t.type({
  output: FailureChatResponseDataT,
  projectId: UUID,
  requestId: UUID,
  sessionId: UUID,
  sessionStatus: SessionStatusT,
});
export type FailureRuntimeOutputEventPayload = t.TypeOf<
  typeof FailureRuntimeOutputEventPayloadT
>;

const SuccessRuntimeOutputEventPayloadT = t.type({
  output: SuccessChatResponseDataT,
  projectId: UUID,
  requestId: UUID,
  sessionId: UUID,
  sessionStatus: SessionStatusT,
});
export type SuccessRuntimeOutputEventPayload = t.TypeOf<
  typeof SuccessRuntimeOutputEventPayloadT
>;

// SuccessRuntimeOutputEventPayloadT should be devided into auth and non auth
export const RuntimeOutputEventPayloadT = t.union([
  FailureRuntimeOutputEventPayloadT,
  SuccessRuntimeOutputEventPayloadT,
]);
export type RuntimeOutputEventPayload = t.TypeOf<
  typeof RuntimeOutputEventPayloadT
>;

export enum HandoverResponseType {
  Slack = "SLACK",
}
const HandoverResponseTypeT = t.keyof({
  [HandoverResponseType.Slack]: null,
});

const HandoverResponseT = t.type({
  resolution: t.type({
    text: t.string,
    raw: t.string,
  }),
  type: ResponseTypeT,
});

const HandoverChatResponseDataT = t.partial({
  message: t.string,
  name: t.string,
  response: HandoverResponseT,
  stack: t.string,
  type: HandoverResponseTypeT,
  userId: t.string,
});

export const HandoverOutputEventPayloadT = t.type({
  output: HandoverChatResponseDataT,
  projectId: UUID,
  requestId: UUID,
  sessionId: UUID,
  sessionStatus: SessionStatusT,
});
export type HandoverOutputEventPayload = t.TypeOf<
  typeof HandoverOutputEventPayloadT
>;

const OutputT = t.type({
  output: ChatResponseDataT,
  requestId: UUID,
  sessionStatus: SessionStatusT,
});
export type Output = t.TypeOf<typeof OutputT>;

export const OutputPayloadT = t.type({
  responses: ResponsesWithTextT,
});
export type OutputPayload = t.TypeOf<typeof OutputPayloadT>;

export const EntityNameWithIdT = t.type({
  id: UUID,
  name: t.string,
});
export type EntityNameWithId = t.TypeOf<typeof EntityNameWithIdT>;

export const EntityNamesWithIdT = nonEmptyArray(EntityNameWithIdT);
export type EntityNamesWithId = t.TypeOf<typeof EntityNamesWithIdT>;

export interface RequestResponse {
  readonly body: string;
  readonly statusCode: number;
}

export enum NluProjectVersion {
  DesignTime = "DesignTime",
  Runtime = "Runtime",
}

export interface NluPayload {
  readonly textInput: string;
  readonly projectVersion: NluProjectVersion;
}

export interface EnginePayload {
  readonly context: EngineContextList;
  readonly queries: Queries;
}

export enum SlackEventType {
  UrlVerification = "url_verification",
  EventCallback = "event_callback",
}

export const UrlVerificationT = t.type({
  token: t.string,
  type: t.literal(SlackEventType.UrlVerification),
  challenge: t.string,
});

export const EventCallbackT = t.type({
  token: t.string,
  type: t.literal(SlackEventType.EventCallback),
  event: t.type({
    channel: t.string,
    channel_type: t.string,
    client_msg_id: t.string,
    event_ts: t.string,
    team: t.string,
    text: t.string,
    ts: t.string,
    type: t.string,
    user: t.string,
  }),
});
export type EventCallback = t.TypeOf<typeof EventCallbackT>;

export const SlackPayloadT = t.union([UrlVerificationT, EventCallbackT]);

export const EventCallbackHeadersT = t.type({
  "x-slack-request-timestamp": t.string,
  "x-slack-signature": t.string,
});

export const ConversationObjectT = t.type({
  id: t.string,
  name: t.string,
});

export const PullPayloadT = t.type({
  sessionId: UUID,
});
export type PullPayload = t.TypeOf<typeof PullPayloadT>;

const PullOutputHandoverResponseT = t.type({
  resolution: t.type({
    text: t.string,
  }),
  type: ResponseTypeT,
});

export const PullOutputT = t.type({
  output: t.type({
    responses: t.array(PullOutputHandoverResponseT),
    type: HandoverResponseTypeT,
    // TODO: isAdmin
  }),
  requestId: UUID,
  sessionStatus: SessionStatusT,
});
export type PullOutput = t.TypeOf<typeof PullOutputT>;

export const SlackWorkspaceT = t.type({
  id: UUID,
  oauthAccessToken: t.string,
  webhookURL: t.string,
  teamId: t.string,
});
export type SlackWorkspace = t.TypeOf<typeof SlackWorkspaceT>;

export interface Auth0TokenPayload {
  readonly client_id: string;
  readonly client_secret: string;
  readonly audience: string;
  readonly grant_type: string;
}

export const Auth0TokenResponseT = t.type({
  access_token: t.string,
  token_type: t.string,
  expires_in: t.number,
});

export const DmsHandoverResponseT = nonEmptyArray(
  t.type({
    responses: nonEmptyArray(DmsResponseT),
  }),
);

export const SpeechDataT = t.type({
  transcript: t.string,
  isFinal: t.boolean,
  response: t.unknown,
  streamingRecognitionConfig: t.unknown,
});
export type SpeechData = t.TypeOf<typeof SpeechDataT>;

export const StartGoogleCloudStreamT = t.type({
  speechContexts: SpeechContextsT,
  languageCode: t.string,
  sessionId: UUID,
});
export type StartGoogleCloudStream = t.TypeOf<typeof StartGoogleCloudStreamT>;

export type OnNewMessage = (a: UUID, b: Input) => TE.TaskEither<Error, void>;

export const UploadFileReceiptT = t.type({
  signedURL: t.string,
  filename: t.string,
});

// TODO: validate as much as possible using pipe and new t.Type<>, etc
// TODO: pay attention to union types (improvements to be type safe?)
