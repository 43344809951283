import "./style.scss";

import close from "../../../../../../../assets/clear-button.svg";
import { useEffect, useRef } from "react";
import { connect, destroy } from "src/modules/avatar/streaming-client-api";
import { SpeechSynthesisVoiceType } from "src/modules/types";

type Props = {
  title: string;
  subtitle: string;
  toggleChat: () => void;
  showCloseButton: boolean;
  speechSynthesisVoiceType?: SpeechSynthesisVoiceType;
  titleAvatar?: string;
  color: string;
};

function Header({
  title,
  subtitle,
  toggleChat,
  showCloseButton,
  speechSynthesisVoiceType,
  titleAvatar,
  color,
}: Props) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (
      speechSynthesisVoiceType !== SpeechSynthesisVoiceType.Avatar ||
      !titleAvatar ||
      !videoRef
    ) {
      return;
    }

    const talkVideo = videoRef.current;

    const asyncFn = async () => {
      await connect(titleAvatar, talkVideo);
    };

    asyncFn();

    return () => {
      destroy(talkVideo);
    };
  }, [speechSynthesisVoiceType, titleAvatar, videoRef]);

  return (
    <div className="rcw-header">
      {showCloseButton && (
        <button className="rcw-close-button" onClick={toggleChat}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="rcw-close"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            width="20px"
            height="20px"
            viewBox="0 0 357 357"
          >
            <g>
              <g id="clear">
                <polygon
                  points="357,35.7 321.3,0 178.5,142.8 35.7,0 0,35.7 142.8,178.5 0,321.3 35.7,357 178.5,214.2 321.3,357 357,321.3     214.2,178.5   "
                  fill={color}
                />
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
        </button>
      )}
      {speechSynthesisVoiceType === SpeechSynthesisVoiceType.Avatar &&
        titleAvatar && (
          <video
            className="avatar"
            width={200}
            height={200}
            autoPlay
            playsInline
            ref={videoRef}
            poster={titleAvatar}
          />
        )}
      <h4 className="rcw-title">{title}</h4>
      <span>{subtitle}</span>
    </div>
  );
}

export default Header;
