import { fromEvent } from "rxjs";
import settings from "../config/settings";
import { OnFields, ShowForm } from "./response";
import { handleResponses } from "./handlers";
import { NonEmptyResponseWithTextT, SpeechSynthesisVoiceType } from "./types";
import { UUID } from "io-ts-types";
import * as E from "fp-ts/Either";
import { pipe } from "fp-ts/lib/function";

let websocket: WebSocket;

const socket = (
  sessionId: string,
  onFields: OnFields,
  showForm: ShowForm,
  fallbackMessage: string,
  speechSynthesisVoiceType: SpeechSynthesisVoiceType,
  lang: string,
  locale: string | null,
  token: string,
  projectId: UUID,
): WebSocket => {
  if (websocket && websocket.readyState === WebSocket.OPEN) {
    return websocket;
  }

  if (
    !websocket ||
    websocket.readyState === WebSocket.CLOSED ||
    websocket.readyState === WebSocket.CLOSING
  ) {
    websocket = new WebSocket(settings.clientHandoverDomain);

    fromEvent(websocket, "open").subscribe((_) => {
      websocket.send(JSON.stringify({ action: "registerSession", sessionId }));
      console.log("connected to socket");
    });

    const message = fromEvent(websocket, "message");

    message.subscribe((e) => {
      const b = e as any;
      const message = JSON.parse(b.data);

      pipe(
        message,
        NonEmptyResponseWithTextT.decode,
        E.fold(
          (e) => {
            console.error(e);
          },
          (m) => {
            handleResponses(
              m,
              onFields,
              showForm,
              fallbackMessage,
              speechSynthesisVoiceType,
              lang,
              locale,
              token,
              projectId,
            );
          },
        ),
      );
    });

    setInterval(() => {
      websocket.send(JSON.stringify({ action: "heartbeat" }));
    }, 120000);
  }

  return websocket;
};

export default socket;
