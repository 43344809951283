import * as IOE from "fp-ts/lib/IOEither";
import toError from "to-error";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as TE from "fp-ts/lib/TaskEither";

export const setItem = (k: string, v: unknown): IOE.IOEither<Error, void> =>
  IOE.tryCatch(
    () =>
      pipe(v, JSON.stringify, (stringified) =>
        localStorage.setItem(k, stringified)
      ),
    toError
  );

export const teSetItem = (k: string, v: unknown): TE.TaskEither<Error, void> =>
  pipe(setItem(k, v), TE.fromIOEither);

export const getItem = (k: string): IOE.IOEither<Error, unknown> =>
  pipe(
    IOE.tryCatch(() => localStorage.getItem(k), toError),
    IOE.chain((a) =>
      pipe(
        a,
        O.fromNullable,
        IOE.fromOption(() => Error(`Could not find ${k}`))
      )
    ),
    IOE.map(JSON.parse)
  );

export const teGetItem = (k: string): TE.TaskEither<Error, unknown> =>
  pipe(getItem(k), TE.fromIOEither);
