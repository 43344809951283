import { post } from "./axios";
import * as TE from "fp-ts/lib/TaskEither";
import { Payload, StartPayload, Input, QuickReply } from "./types";
import { UUID } from "io-ts-types";
import { AxiosResponse } from "axios";

export const startChat = (
  projectId: UUID,
  parentLocation: Location,
  token: string,
): TE.TaskEither<Error, AxiosResponse<unknown>> => {
  const payload: StartPayload = {
    projectId,
    meta: {
      location: parentLocation,
      navigator: {
        language: window.navigator.language,
        languages: window.navigator.languages,
        userAgent: window.navigator.userAgent,
        webdriver: window.navigator.webdriver,
      },
    },
  };

  return post("start", payload, token);
};

export const sendMessage = (
  sessionId: UUID,
  input: Input,
  quickReply: QuickReply | null,
  parentLocation: Location,
  token: string,
): TE.TaskEither<Error, AxiosResponse<unknown>> => {
  const payload: Payload = {
    sessionId,
    input,
    quickReply,
    meta: {
      location: parentLocation,
      navigator: {
        language: window.navigator.language,
        languages: window.navigator.languages,
        userAgent: window.navigator.userAgent,
        webdriver: window.navigator.webdriver,
      },
    },
  };

  return post("chat", payload, token);
};
