import {
  renderCustomComponent,
  addResponseMessage,
  setQuickButtons,
} from "../react-chat-widget";
import ErrorResponse from "../ErrorResponse";
import {
  ProcessedFormResponse,
  QuickReply,
  RteResponseWithText,
  SpeechSynthesisVoiceType,
} from "./types";
import * as O from "fp-ts/lib/Option";
import speak from "src/modules/speak";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import { join } from "fp-ts-extras/lib/String";
import {
  toHTML,
  toPlainText,
} from "src/react-chat-widget/src/components/Widget/components/Conversation/components/Messages/components/Message";
import { talk } from "./avatar/streaming-client-api";
import { UUID } from "io-ts-types";

export const addErrorResponse = (message: string) => {
  renderCustomComponent(ErrorResponse, { message }, true);
};

export const addRteResponse = (
  response: RteResponseWithText,
  speechSynthesisVoiceType: SpeechSynthesisVoiceType,
  voiceID: string,
  locale: string | null,
  authorizationToken: string,
  projectId: UUID,
) => {
  pipe(
    response.resolution,
    A.map((a) => a.text),
    join(" "),
    (message) => {
      addResponseMessage(message);

      const sanitizedHTML = toHTML(message);
      const plainText = toPlainText(sanitizedHTML);

      if (speechSynthesisVoiceType === SpeechSynthesisVoiceType.Avatar) {
        talk(plainText, voiceID);
      } else if (
        speechSynthesisVoiceType === SpeechSynthesisVoiceType.VoiceOnly
      ) {
        speak(plainText, voiceID, locale, authorizationToken, projectId);
      }
    },
  );
};

export const addSlackResponse = (message: string) => {
  addResponseMessage(message);
};

export const addQuickReplyResponse = (replies: QuickReply[]) => {
  pipe(
    replies,
    A.map((reply) => ({
      label: reply.display,
      value: reply,
    })),
    setQuickButtons,
  );
};

export type OnFields = React.Dispatch<
  React.SetStateAction<O.Option<ProcessedFormResponse>>
>;

export type ShowForm = React.Dispatch<React.SetStateAction<boolean>>;

export const addFormResponse = (
  fields: ProcessedFormResponse,
  onFields: OnFields,
  showForm: ShowForm,
) => {
  onFields(O.some(fields));
  showForm(true);
};
