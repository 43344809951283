import {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import { GlobalState } from "../../../../../../store/types";

import {
  getCaretIndex,
  isFirefox,
  updateCaret,
  insertNodeAtCaret,
  getSelection,
} from "../../../../../../utils/contentEditable";

import "./style.scss";

import emoji from "../../../../../../../assets/icon-smiley.svg";

import { NodeHtmlMarkdown } from "node-html-markdown";
import { Subject } from "rxjs";
import { Loading } from "../../../../../../../../Loading";

const brRegex = /<br>/g;

type Props = {
  placeholder: string;
  disabledInput: boolean;
  autofocus: boolean;
  sendMessage: (event: any) => Promise<void>;
  buttonAlt: string;
  onPressEmoji: () => void;
  onChangeSize: (event: any) => void;
  onTextInputChange?: (event: any) => void;
  emojis?: boolean;

  isLoading: boolean;
  stopRecognition: Subject<boolean>;
  inputRef: React.MutableRefObject<HTMLDivElement>;
  dirty: boolean;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
};

function Sender(
  {
    sendMessage,
    placeholder,
    disabledInput,
    autofocus,
    onTextInputChange,
    buttonAlt,
    onPressEmoji,
    onChangeSize,
    emojis,

    isLoading,
    stopRecognition,
    inputRef,
    dirty,
    setDirty,
  }: Props,
  ref: any,
) {
  const showChat = useSelector((state: GlobalState) => state.behavior.showChat);
  const refContainer = useRef<HTMLDivElement>(null);
  const [enter, setEnter] = useState(false);
  const [firefox, setFirefox] = useState(false);
  const [height, setHeight] = useState(0);
  // @ts-ignore
  useEffect(() => {
    if (showChat && autofocus) inputRef.current?.focus();
  }, [showChat]);
  useEffect(() => {
    setFirefox(isFirefox());
  }, []);

  useImperativeHandle(ref, () => {
    return {
      onSelectEmoji: handlerOnSelectEmoji,
    };
  });

  const handlerOnChange = (event: any) => {
    setDirty(event.target.innerHTML !== "");
    onTextInputChange && onTextInputChange(event);
  };

  const handlerSendMessage = async () => {
    const el = inputRef.current;
    if (el.innerHTML) {
      stopRecognition.next(true);

      const html = el.innerHTML;
      el.innerHTML = "";
      setDirty(false);

      await sendMessage(NodeHtmlMarkdown.translate(html));
    }
  };

  const handlerOnSelectEmoji = (emoji: any) => {
    const el = inputRef.current;
    const { start, end } = getSelection(el);
    if (el.innerHTML) {
      const firstPart = el.innerHTML.substring(0, start);
      const secondPart = el.innerHTML.substring(end);
      el.innerHTML = `${firstPart}${emoji.native}${secondPart}`;
    } else {
      el.innerHTML = emoji.native;
    }
    setDirty(true);
    updateCaret(el, start, emoji.native.length);
  };

  const handlerOnKeyPress = (event: any) => {
    const el = inputRef.current;

    if (event.charCode == 13 && !event.shiftKey) {
      event.preventDefault();
      handlerSendMessage();
    }
    if (event.charCode === 13 && event.shiftKey) {
      event.preventDefault();
      insertNodeAtCaret(el);
      setEnter(true);
    }
  };

  // TODO use a context for checkSize and toggle picker
  const checkSize = () => {
    const senderEl = refContainer.current;
    if (senderEl && height !== senderEl.clientHeight) {
      const { clientHeight } = senderEl;
      setHeight(clientHeight);
      onChangeSize(clientHeight ? clientHeight - 1 : 0);
    }
  };

  const handlerOnKeyUp = (event: any) => {
    const el = inputRef.current;
    if (!el) return true;
    // Conditions need for firefox
    if (firefox && event.key === "Backspace") {
      if (el.innerHTML.length === 1 && enter) {
        el.innerHTML = "";
        setEnter(false);
      } else if (brRegex.test(el.innerHTML)) {
        el.innerHTML = el.innerHTML.replace(brRegex, "");
      }
    }
    setDirty(el.innerHTML !== "");
    checkSize();
  };

  const handlerOnKeyDown = (event: any) => {
    const el = inputRef.current;

    if (event.key === "Backspace" && el) {
      const caretPosition = getCaretIndex(inputRef.current);
      const character = el.innerHTML.charAt(caretPosition - 1);
      if (character === "\n") {
        event.preventDefault();
        event.stopPropagation();
        el.innerHTML =
          el.innerHTML.substring(0, caretPosition - 1) +
          el.innerHTML.substring(caretPosition);
        updateCaret(el, caretPosition, -1);
      }
    }
    setDirty(el.innerHTML !== "");
  };

  const handlerPressEmoji = () => {
    onPressEmoji();
    checkSize();
  };

  return (
    <div ref={refContainer} className="rcw-sender">
      {emojis ? (
        <button
          className="rcw-picker-btn"
          type="submit"
          onClick={handlerPressEmoji}
        >
          <img src={emoji} className="rcw-picker-icon" alt="" />
        </button>
      ) : null}
      <div
        className={cn("rcw-new-message", {
          "rcw-message-disable": disabledInput,
        })}
      >
        <div
          spellCheck
          className="rcw-input"
          role="textbox"
          contentEditable={!disabledInput}
          ref={inputRef}
          data-placeholder={placeholder}
          onInput={handlerOnChange}
          onKeyPress={handlerOnKeyPress}
          onKeyUp={handlerOnKeyUp}
          onKeyDown={handlerOnKeyDown}
        />
      </div>

      <button
        type="submit"
        className="rcw-send"
        onClick={handlerSendMessage}
        disabled={isLoading}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            width="512px"
            height="512px"
            viewBox="0 0 535.5 535.5"
            className="rcw-send-icon"
          >
            <g>
              <g id="send">
                <polygon
                  points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75   "
                  fill="#cbcbcb"
                  className={cn({
                    "rcw-send-icon-svg": dirty,
                  })}
                />
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
        )}
      </button>
    </div>
  );
}

export default forwardRef(Sender);
