import { pipe } from "fp-ts/lib/function";
import * as TE from "fp-ts/lib/TaskEither";
import teTryCatch from "./teTryCatch";
import { Observable, Subject, timer } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import hark from "hark";
import { UUID } from "io-ts-types";
import { post } from "./axios";
import { voidTask } from "./handlers";
import mime from "mime-types";
import AudioRecorder from "audio-recorder-polyfill";

const stopSpeakingTimeout = 2000;

const supportedExts = ["m4a", "mp3", "webm", "mp4", "mpga", "wav", "mpeg"];

const speechEventStreams = (speechEvents: hark.Harker) => {
  const stoppedSpeaking = new Observable((subscribe) => {
    speechEvents.on("stopped_speaking", () => {
      subscribe.next();
    });
  });

  const speaking = new Observable((subscribe) => {
    speechEvents.on("speaking", () => {
      subscribe.next();
    });
  });

  return { stoppedSpeaking, speaking };
};

const stream2Transcript =
  (
    onInputValueChange: (input: string) => void,
    onDone: (a: string) => void,
    speechContexts: string[],
    stopRecognition: Subject<boolean>,
    processRecognition: Subject<boolean>,
    sessionId: UUID,
    canvas: HTMLCanvasElement,
    color: string,
    onProcess: React.Dispatch<React.SetStateAction<boolean>>,
    token: string,
  ) =>
  (stream: MediaStream) => {
    // Create an audio context
    const audioContext = new AudioContext();

    // Create a media stream source node from the microphone input
    const sourceNode = audioContext.createMediaStreamSource(stream);

    createAnalyser(stream, audioContext, sourceNode, canvas, color);

    // Create a new instance of the MediaRecorder object
    // safari produces mp4 natively and openAI doesn't support it
    const recorder = new AudioRecorder(stream);

    // Create an empty audio buffer to store the recorded audio
    let audioBuffer: Blob[] = [];

    // Listen for data available events and add the data to the audio buffer
    recorder.addEventListener("dataavailable", function (event: BlobEvent) {
      audioBuffer.push(event.data);
    });

    // Listen for the recorder stopping and create a download link for the recorded audio
    recorder.addEventListener("stop", function () {
      const audioBlob = new Blob(audioBuffer, { type: recorder.mimeType });

      // Create a new FormData object
      const formData = new FormData();

      const ext = mime.extension(recorder.mimeType);

      // if ext is not supported, it should find another extension.
      // for now we simplify this to use first supported ext
      const hotfixExt =
        supportedExts.find((e) => e === ext) == null ? supportedExts[0] : ext;

      // Create a new File object from the audio file
      const audioFile = new File([audioBlob], `voice.${hotfixExt}`, {
        type: audioBlob.type,
      });

      // Append the audio file to the form data object
      formData.append("file", audioFile);

      formData.append("sessionId", sessionId);

      pipe(
        post("transcriptions", formData, token),
        TE.fold(
          (error) => {
            console.error(
              "There was a problem with the fetch operation:",
              error,
            );
            done("");
            return voidTask;
          },
          (response) => {
            done(response.data as string);
            return voidTask;
          },
        ),
      )();
    });

    const speechEvents = hark(stream);

    const { stoppedSpeaking, speaking } = speechEventStreams(speechEvents);

    const stop = () => {
      recorder.stop();
      speechEvents.stop();
      sourceNode.disconnect();
      onProcess(true);
      subscription.unsubscribe();
      recognitionSubscription.unsubscribe();
    };

    const recognitionSubscription = processRecognition.subscribe((process) => {
      if (process) {
        stop();
      }
    });

    const subscription = pipe(
      stoppedSpeaking,
      switchMap(() => pipe(timer(stopSpeakingTimeout), takeUntil(speaking))),
    ).subscribe(() => {
      // stop();
    });

    recorder.start();

    function done(transcript: string) {
      onDone(transcript);
      stopRecognition.next(true);
    }

    return stop;
  };

const recognise = (
  onInputValueChange: (input: string) => void,
  onDone: (a: string) => void,
  speechContextPhrases: string[],
  stopRecognition: Subject<boolean>,
  processRecognition: Subject<boolean>,
  sessionId: UUID,
  canvas: HTMLCanvasElement,
  color: string,
  onProcess: React.Dispatch<React.SetStateAction<boolean>>,
  projectId: UUID,
  token: string,
): TE.TaskEither<Error, () => void> => {
  window.gtag("event", "recognise", {
    event_category: "speech",
    session_id: sessionId,
    project_id: projectId,
  });

  const speechContexts = pipe(speechContextPhrases);

  return pipe(
    teTryCatch(() => navigator.mediaDevices.getUserMedia({ audio: true })),
    TE.map(
      stream2Transcript(
        onInputValueChange,
        onDone,
        speechContexts,
        stopRecognition,
        processRecognition,
        sessionId,
        canvas,
        color,
        onProcess,
        token,
      ),
    ),
  );
};

export default recognise;

// generate wave
var createAnalyser = (
  stream: MediaStream,
  audioContext: AudioContext,
  sourceNode: MediaStreamAudioSourceNode,
  canvas: HTMLCanvasElement,
  color: string,
) => {
  var binaryData = [];
  binaryData.push(stream);

  var analyser = audioContext.createAnalyser();
  sourceNode.connect(analyser);

  drawSpectrum(analyser, canvas, color);
};

var drawSpectrum = function (
  analyser: AnalyserNode,
  canvas: HTMLCanvasElement,
  color: string,
) {
  const cwidth = canvas.width,
    cheight = canvas.height,
    meterWidth = 24,
    gap = 6,
    meterNum = cwidth / (meterWidth + gap),
    ctx = canvas.getContext("2d");
  if (!ctx) {
    return;
  }
  const gradient = ctx.createLinearGradient(0, 0, 0, cheight);
  gradient.addColorStop(1, "#cbcbcb");
  gradient.addColorStop(0.3, "#cbcbcb");
  gradient.addColorStop(0, "#f4f7f9");
  ctx.fillStyle = gradient;
  var drawMeter = function () {
    var array = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(array);

    var step = Math.round(array.length / meterNum);
    ctx.clearRect(0, 0, cwidth, cheight);
    for (var i = 0; i < meterNum; i++) {
      var value = array[i * step];

      ctx.fillRect(
        i * (meterWidth + gap),
        cheight - value * 1.5,
        meterWidth,
        cheight * 1.5,
      );
    }
    requestAnimationFrame(drawMeter);
  };
  requestAnimationFrame(drawMeter);
};
