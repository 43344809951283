export const Loading = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      className="animate-spin text-white rcw-send-icon"
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke="#cbcbcb"
        strokeWidth="4"
        opacity="0.25"
      ></circle>
      <path
        fill="#cbcbcb"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        className="rcw-send-icon-svg"
      ></path>
    </svg>
  );
};
