import { teGetItem, teSetItem } from "./localStorage";
import { pipe } from "fp-ts/lib/function";
import * as TE from "fp-ts/lib/TaskEither";

export const getProject =
  window.location.pathname === "/"
    ? teGetItem("projectId")
    : pipe(window.location.pathname.slice(1), (projectId) =>
        pipe(
          teSetItem("projectId", projectId),
          TE.chain((_) => TE.right(projectId)),
        ),
      );
